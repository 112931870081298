import React from "react";
import { Routes, Route } from "react-router-dom";
import { ScrollToTop } from "../components/ScrollToTop";
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Home } from '../pages/Home';
import { PrivacyPolicy } from "../pages/PrivacyPolicy";
import { TermsOfServices } from "../pages/TermsOfServices";

export const Layout = ({ children }) => {
    return (
        <>
            {/* Included the scrollToTop so that when we redirect to any page page will visible from the top end */}
            <ScrollToTop />

            {/* Header Section */}
            <Header />

            {/* Main Page Content Section */}
            <div className="main-content">
                {children}
            </div>

            {/* Footer Section */}
            <Footer />
        </>
    )
}
