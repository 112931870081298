import React from 'react';
import SpotifyIcon from '../../../assets/images/admin/Spotify.svg';
import AppleIcon from '../../../assets/images/admin/Apple.svg';
import StartIcon from '../../../assets/images/admin/Star.svg';
import AmazonIcon from '../../../assets/images/admin/Amazon.svg';
import GroupIcon from '../../../assets/images/admin/Group-line.svg';
import StreamIcon from '../../../assets/images/icons/stream.svg';
import FanbaseIcon from '../../../assets/images/icons/fanbase.svg';
import BestTrack from '../../../assets/images/icons/best-track.svg';
import Listeners from '../../../assets/images/icons/listeners.svg';
import FollowersIcon from '../../../assets/images/icons/followers.svg';
import MetaIcon from '../../../assets/images/admin/meta.svg';
import InstaIcon from '../../../assets/images/admin/Instagram.png';
import TiktokIcon from '../../../assets/images/admin/Tiktok.png';
import YoutubeIcon from '../../../assets/images/admin/Youtube.png';
import StarOutlinedIcon from '../../../assets/images/admin/Star-outlined.svg';
import SpotifyLineIcon from '../../../assets/images/admin/spotify-lines.svg';
import ButtonIcon from '../../../assets/images/icons/button-icon.svg';
import AreaChart from '../charts/AreaChart';
import { useNavigate } from 'react-router-dom';

export const GrowthEngine = () => {
    const navigate = useNavigate()
    return (
        <div className='GrowthEngine-page py-5'>
            <div className="container max-w-full">
                <div className="card bg-gradient-to-r from-theme_clr1 to-theme_clr2 rounded-xl mb-5 p-4 lg:p-5">
                    <div className='flex flex-wrap items-center justify-between gap-[11px]'>
                    <h5 className='flex items-center text-white text-[23px] font-manrope font-bold leading-tight'>Your Growth Engine <i className="bi bi-bar-chart-line-fill text-4xl leading-none ms-8"></i></h5>
                    <button className='btn-custom2 w-[192px] flex items-center justify-center gap-5 shadow-lg' onClick={()=>navigate('/admin/growth-engine/campaign')}>New Campaign <i className='bi bi-plus-circle text-[20px]'></i></button>
                    </div>
                </div>

                {/* Performance Section */}
                <div className="performance-section bg-theme_clr1 rounded-xl mb-5 p-4 lg:px-5 pt-[26px] pb-[30px]">
                    <div className="header-section flex flex-wrap items-center justify-between gap-2 mb-[10px] md:mb-[21px]">
                        <div className="flex flex-wrap gap-6">
                            <select name="" id="" className='bg-transparent text-white text-base font-manrope border-0 focus:outline-none'>
                                <option value="0">All time statistics</option>
                                <option value="1">Monthly audience</option>
                                <option value="2">Quarterly audience</option>
                                <option value="3">Yearly audience</option>
                            </select>
                            <div className="icons flex items-center gap-6">
                                <img src={InstaIcon} alt="Instagram" className='block w-[28px]' />
                                <img src={TiktokIcon} alt="Tiktok" className='block w-[34px]' />
                                <img src={YoutubeIcon} alt="Youtube" className='block w-[34px]' />
                            </div>
                        </div>
                        <button type='button' className='btn-custom3'>View Growth Analytics</button>
                    </div>
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 2xl:gap-[22px] flex-nowrap overflow-auto">
                        <div className="grid-item">
                            <div className="info-box h-full bg-white font-manrope flex flex-col justify-between gap-2 rounded-xl p-4 2xl:p-5">
                                <div className="icon mb-1">
                                    <img src={FollowersIcon} alt="Followers" />
                                </div>
                                <div className="chart-wrapper" style={{ marginLeft: '-10px' }}>
                                    <AreaChart />
                                </div>
                                <div className="cover">
                                    <div className="text-theme_clr1 text-[12.26px] font-bold mb-[2px]">15.0K</div>
                                    <div className="text-theme_clr1 text-[12.8px]">Followers</div>
                                </div>
                            </div>

                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-white font-manrope flex flex-col justify-between gap-2 rounded-xl p-4 2xl:p-5">
                                <div className="icon mb-1">
                                    <img src={FanbaseIcon} alt="Cost-Per-Fan" />
                                </div>
                                <div className="chart-wrapper" style={{ marginLeft: '-10px' }}>
                                    <AreaChart />
                                </div>
                                <div className="cover">
                                    <div className="text-theme_clr1 text-[12.26px] font-bold mb-[2px]">50.0k</div>
                                    <div className="text-theme_clr1 text-[12.8px]">Cost-Per-Fan</div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-white font-manrope flex flex-col justify-between gap-2 rounded-xl p-4 2xl:p-5">
                                <div className="icon">
                                    <img src={BestTrack} alt="Best Track" />
                                    <div className="text-black text-[12.26px] font-bold mt-2">Right Now V2</div>
                                </div>
                                <div className="chart-wrapper">
                                    {/* <AreaChart /> */}
                                    <img src={require('../../../assets/images/best-track2.jpg')} alt="Best Performing Song" className='block w-[400px] mx-auto' />
                                </div>
                                <div className="cover">
                                    <div className="text-theme_clr1 text-[12.26px] font-bold mb-[2px]">400K Views</div>
                                    <div className="text-theme_clr1 text-[12.8px]">Best Performing Song</div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-white font-manrope flex flex-col justify-between gap-2 rounded-xl p-4 2xl:p-5">
                                <div className="icon mb-1">
                                    <i className="bi bi-currency-dollar text-4xl leading-none"></i>
                                </div>
                                <div className="chart-wrapper" style={{ marginLeft: '-10px' }}>
                                    <AreaChart />
                                </div>
                                <div className="cover">
                                    <div className="text-theme_clr1 text-[12.26px] font-bold mb-[2px]">$100</div>
                                    <div className="text-theme_clr1 text-[12.8px]">Expenditure</div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item sm:col-span-2 xl:col-span-1">
                            <div className="info-box h-full bg-white font-manrope flex flex-col justify-between gap-2 rounded-xl p-4 2xl:p-5">
                                <div className="icon mb-1">
                                    <img src={StreamIcon} alt="Streams" />
                                </div>
                                <div className="chart-wrapper" style={{ marginLeft: '-10px' }}>
                                    <AreaChart />
                                </div>
                                <div className="cover">
                                    <div className="text-theme_clr1 text-[12.26px] font-bold mb-[2px]">10.3M</div>
                                    <div className="text-theme_clr1 text-[12.8px]">Impressions</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card bg-gradient-to-r from-theme_clr1 to-theme_clr2 rounded-xl mb-5 p-4 lg:p-5">
                    <h5 className='text-white text-[23px] font-manrope font-bold leading-tight mb-4'>Launch campaigns to grow your fanbase, Karan.</h5>
                    <div className="input-wrapper flex flex-wrap gap-[11px]">
                        <input type="text" className='flex-1 bg-transparent text-white placeholder:text-white text-base font-jakarta font-medium border border-white rounded-full px-5 py-3' placeholder='Use Amplitude in crafting the perfect campaign based on your vision.' required />
                        <button className='btn-custom2 w-[192px] flex items-center justify-center gap-5 shadow-lg'>Submit <img src={ButtonIcon} alt="Button Icon" /></button>
                    </div>
                </div>

                {/* Grid Listing Section */}
                <div className="performance-section">
                    <div className="grid sm:grid-cols-2 xl:grid-cols-4 gap-4">
                        <div className="grid-item">
                            <div className="info-box h-full bg-theme_clr1 text-white flex flex-col justify-between rounded-xl p-4 xl:p-5">
                                <div className="title-info text-[23px] font-bold font-manrope flex items-center gap-2 leading-tight mb-4">
                                    <img src={MetaIcon} alt="Meta" className='inline' />Meta Campaigns
                                </div>
                                <div className="card-wrapper font-lato mb-3">
                                    <div className="song-card w-full inline-flex items-center rounded-sm overflow-hidden mb-1">
                                        <img src={require('../../../assets/images/Aditya-Ojha.png')} alt="ProfessorU" className='w-[60px] min-w-[60px] h-[60px] object-cover' />
                                        <div className="info bg-white text-black truncate px-3 py-2">
                                            <div className="song-name text-[15px] font-semibold capitalize truncate">This is dummy song</div>
                                            <div className="singer-name text-sm capitalize truncate">Karan Thapar</div>
                                        </div>
                                    </div>
                                    <div className="info text-[15px] flex flex-wrap justify-between gap-1">
                                        <div className="left">
                                            <div className='capitalize'>Increase Streams Campaign</div>
                                            <div className="date text-[#ABABAB] text-xs uppercase tracking-[2.4px]">Ended FEB 14</div>
                                        </div>
                                        <div className="right">
                                            <img src={SpotifyLineIcon} alt="Spotify" className='mt-1'/>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-wrapper font-lato mb-3">
                                    <div className="song-card w-full inline-flex items-center rounded-sm overflow-hidden mb-1">
                                        <img src={require('../../../assets/images/Aditya-Ojha.png')} alt="ProfessorU" className='w-[60px] min-w-[60px] h-[60px] object-cover' />
                                        <div className="info bg-white text-black truncate px-3 py-2">
                                            <div className="song-name text-[15px] font-semibold capitalize truncate">This is dummy song</div>
                                            <div className="singer-name text-sm capitalize truncate">Karan Thapar</div>
                                        </div>
                                    </div>
                                    <div className="info text-[15px] flex flex-wrap justify-between gap-1">
                                        <div className="left">
                                            <div className='capitalize'>Increase Instagram Followers</div>
                                            <div className="date text-[#ABABAB] text-xs uppercase tracking-[2.4px]">Dec 15</div>
                                        </div>
                                        <div className="right">
                                            <img src={InstaIcon} alt="Instagram" className='w-[24px] mt-1'/>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-wrapper font-lato mb-3">
                                    <div className="song-card w-full inline-flex items-center rounded-sm overflow-hidden mb-1">
                                        <img src={require('../../../assets/images/Aditya-Ojha.png')} alt="ProfessorU" className='w-[60px] min-w-[60px] h-[60px] object-cover' />
                                        <div className="info bg-white text-black truncate px-3 py-2">
                                            <div className="song-name text-[15px] font-semibold capitalize truncate">late night drives clear the mind</div>
                                            <div className="singer-name text-sm capitalize truncate">DREAMSNDELUSIONS</div>
                                        </div>
                                    </div>
                                    <div className="info text-[15px] flex flex-wrapc justify-between gap-1">
                                        <div className="left">
                                            <div className='capitalize'>Grow Playlist Campaign</div>
                                            <div className="date text-[#ABABAB] text-xs uppercase tracking-[2.4px]">Nov 15</div>
                                        </div>
                                        <div className="right">
                                            <img src={SpotifyLineIcon} alt="Spotify" className='mt-1'/>
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-wrapper">
                                    <button type='button' className='btn-custom4'>View History</button>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-theme_clr1 text-white flex flex-col justify-between rounded-xl p-4 xl:p-5">
                                <div className="title-info text-[23px] font-bold font-manrope flex items-center gap-2 leading-tight mb-4">
                                    <img src={TiktokIcon} alt="Tiktok" className='block w-[34px]' />TikTok Campaigns
                                </div>
                                <div className="card-wrapper font-lato mb-3">
                                    <div className="song-card w-full inline-flex items-center rounded-sm overflow-hidden mb-1">
                                        <img src={require('../../../assets/images/Aditya-Ojha.png')} alt="ProfessorU" className='w-[60px] min-w-[60px] h-[60px] object-cover' />
                                        <div className="info bg-white text-black truncate px-3 py-2">
                                            <div className="song-name text-[15px] font-semibold lowercase truncate">www.karanmusic.art</div>
                                            <div className="singer-name text-sm capitalize truncate">CASHRULES Tee</div>
                                        </div>
                                    </div>
                                    <div className="info text-[15px] flex flex-wrap justify-between gap-1">
                                        <div className="left">
                                            <div className='capitalize'>Increase Merch Sales</div>
                                            <div className="date text-[#ABABAB] text-xs uppercase tracking-[2.4px]">Jul 14</div>
                                        </div>
                                        <div className="right">
                                            <i className="bi bi-globe text-2xl"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-wrapper font-lato mb-3">
                                    <div className="song-card w-full inline-flex items-center rounded-sm overflow-hidden mb-1">
                                        <img src={require('../../../assets/images/Aditya-Ojha.png')} alt="ProfessorU" className='w-[60px] min-w-[60px] h-[60px] object-cover' />
                                        <div className="info bg-white text-black truncate px-3 py-2">
                                            <div className="song-name text-[15px] font-semibold capitalize truncate">This is dummy song</div>
                                            <div className="singer-name text-sm capitalize truncate">Karan Thapar</div>
                                        </div>
                                    </div>
                                    <div className="info text-[15px] flex flex-wrap justify-between gap-1">
                                        <div className="left">
                                            <div className='capitalize'>Grow Pre-Saves Campaign</div>
                                            <div className="date text-[#ABABAB] text-xs uppercase tracking-[2.4px]">NOV 15</div>
                                        </div>
                                        <div className="right">
                                            <i className="bi bi-download text-2xl"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-wrapper font-lato mb-3">
                                    <div className="song-card w-full inline-flex items-center rounded-sm overflow-hidden mb-1">
                                        <img src={require('../../../assets/images/Aditya-Ojha.png')} alt="ProfessorU" className='w-[60px] min-w-[60px] h-[60px] object-cover' />
                                        <div className="info bg-white text-black truncate px-3 py-2">
                                            <div className="song-name text-[15px] font-semibold capitalize truncate">@wrldkaran</div>
                                            <div className="singer-name text-sm capitalize truncate">Karan Thapar</div>
                                        </div>
                                    </div>
                                    <div className="info text-[15px] flex flex-wrap justify-between gap-1">
                                        <div className="left">
                                            <div className='capitalize'>Grow TikTok followers</div>
                                            <div className="date text-[#ABABAB] text-xs uppercase tracking-[2.4px]">Nov 15</div>
                                        </div>
                                        <div className="right">
                                            <img src={TiktokIcon} alt="Tiktok" className='block w-[24px]' />
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-wrapper">
                                    <button type='button' className='btn-custom4'>View History</button>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-theme_clr1 text-white flex flex-col justify-between rounded-xl p-4 xl:p-5">
                                <div className="title-info text-[23px] font-bold font-manrope flex items-center gap-2 leading-tight mb-4">
                                    <img src={YoutubeIcon} alt="Youtube" className='block w-[34px]' />YouTube Campaigns
                                </div>
                                <div className="card-wrapper font-lato mb-3">
                                    <div className="song-card w-full inline-flex items-center rounded-sm overflow-hidden mb-1">
                                        <img src={require('../../../assets/images/Aditya-Ojha.png')} alt="ProfessorU" className='w-[60px] min-w-[60px] h-[60px] object-cover' />
                                        <div className="info bg-white text-black truncate px-3 py-2">
                                            <div className="song-name text-[15px] font-semibold capitalize truncate">This is dummy song</div>
                                            <div className="singer-name text-sm capitalize truncate">Karan Thapar</div>
                                        </div>
                                    </div>
                                    <div className="info text-[15px] flex flex-wrap justify-between gap-1">
                                        <div className="left">
                                            <div className='capitalize'>Grow Subscribers</div>
                                            <div className="date text-[#ABABAB] text-xs uppercase tracking-[2.4px]">Jul 14</div>
                                        </div>
                                        <div className="right">
                                            <img src={YoutubeIcon} alt="Youtube" className='block w-[32px]' />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-wrapper font-lato mb-3">
                                    <div className="song-card w-full inline-flex items-center rounded-sm overflow-hidden mb-1">
                                        <img src={require('../../../assets/images/Aditya-Ojha.png')} alt="ProfessorU" className='w-[60px] min-w-[60px] h-[60px] object-cover' />
                                        <div className="info bg-white text-black truncate px-3 py-2">
                                            <div className="song-name text-[15px] font-semibold capitalize truncate">This is dummy song</div>
                                            <div className="singer-name text-sm capitalize truncate">Karan Thapar</div>
                                        </div>
                                    </div>
                                    <div className="info text-[15px] flex flex-wrap justify-between gap-1">
                                        <div className="left">
                                            <div className='capitalize'>Grow Video Views</div>
                                            <div className="date text-[#ABABAB] text-xs uppercase tracking-[2.4px]">Nov 15</div>
                                        </div>
                                        <div className="right">
                                            <img src={YoutubeIcon} alt="Youtube" className='block w-[32px]' />  
                                        </div>
                                    </div>
                                </div>
                                <div className="card-wrapper font-lato mb-3">
                                    <div className="song-card w-full inline-flex items-center rounded-sm overflow-hidden mb-1">
                                        <img src={require('../../../assets/images/Aditya-Ojha.png')} alt="ProfessorU" className='w-[60px] min-w-[60px] h-[60px] object-cover' />
                                        <div className="info bg-white text-black truncate px-3 py-2">
                                            <div className="song-name text-[15px] font-semibold capitalize truncate">This is dummy song</div>
                                            <div className="singer-name text-sm capitalize truncate">Karan Thapar</div>
                                        </div>
                                    </div>
                                    <div className="info text-[15px] flex flex-wrap justify-between gap-1">
                                        <div className="left">
                                            <div className='capitalize'>Grow Shorts Views</div>
                                            <div className="date text-[#ABABAB] text-xs uppercase tracking-[2.4px]">Nov 15</div>
                                        </div>
                                        <div className="right">
                                            <img src={YoutubeIcon} alt="Youtube" className='block w-[32px]' />
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-wrapper">
                                    <button type='button' className='btn-custom4'>View History</button>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-theme_clr1 text-white rounded-xl p-4 xl:p-5">
                                <div className="title-info text-[23px] font-bold font-manrope flex items-center gap-2 leading-tight mb-6">
                                    <img src={require('../../../assets/images/admin/Amplitude-Info.png')} alt="Amplitude Info" className='w-[30px] h-[30px]' />Amplitude Insight
                                </div>
                                <div className="desc-wrapper flex flex-col gap-6">
                                    <div className="text-center">
                                        <p className='text-sm font-bold mb-2'> ‘Right Now V2’ is trending up; consider promoting it further.</p>
                                        <button type='button' className='btn-custom3 w-full ! !py-3'>Run Streams Conversions</button>
                                    </div>
                                    <div className="text-center">
                                        <p className='text-sm font-bold mb-2'>Take Control of your promotional needs.</p>
                                        <button type='button' className='btn-custom3 w-full !py-3'>Grow your Own Playlist</button>
                                    </div>
                                    <div className="text-center">
                                        <p className='text-sm font-bold mb-2'>Get fans to pre-save this release and every release going forward.</p>
                                        <button type='button' className='btn-custom3 w-full !py-3'>Run a Pre-Save Camapaign</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
