import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PublicistIcon from '../../assets/images/icons/publicist.svg';
import MarketingManagerIcon from '../../assets/images/icons/marketing-manager.svg';
import ArtistIcon from '../../assets/images/icons/artist.svg';
import AudioIcon from '../../assets/images/icons/audio.svg';
import CreatorIcon from '../../assets/images/icons/creator.svg';
import AnalystIcon from '../../assets/images/icons/analyst.svg';
import ProfessorUIcon from '../../assets/images/icons/professor.svg';
import AmplitudeIcon from '../../assets/images/icons/button-icon.svg';
import StarIcon from '../../assets/images/icons/start-icon.svg';
import CrossIcon from '../../assets/images/icons/cross.svg';

export const AdminSidebar = ({ isCollapsed, toggleSidebar }) => {

    // Close Sidebar Drawer
    const closeSidebar = () => {
        document.body.classList.remove('admin-sidebar-open');
    };

    return (
        <aside className={`admin_sidebar fixed top-0 left-0 ${isCollapsed ? 'w-[70px]' : 'w-[200px]'} h-screen -translate-x-full md:translate-x-0 z-50`}>
            {/* Main Navbar Section */}
            <nav className="bg-gradient-to-b from-theme_clr1 to-theme_clr2 h-full px-2 py-4 2xl:py-5">
                {/* <div className="flex gap-4 xl:gap-8 items-center justify-between"> */}
                <div className='flex flex-col gap-4 md:gap-6 h-full'>
                    <div className={`top-section logo-wrapper flex items-center ${isCollapsed ? 'justify-center' : 'justify-between'} max-md:border-b max-md:border-slate-600 max-md:pb-3`}>
                        <Link to={'/'}>
                            {isCollapsed ?
                                <img src={require('../../assets/images/short-logo.png')} alt="Logo" className="w-[30px] h-[40px] mx-auto" title='Expand Sidebar' /> :
                                <img src={require('../../assets/images/logo.png')} alt="Logo" className="max-w-[100px] md:max-w-[110px] mx-auto" />
                            }
                        </Link>
                        <button onClick={closeSidebar} className="text-white md:hidden">
                            <img src={CrossIcon} alt="Cancel" className="block" />
                        </button>
                        {isCollapsed ?
                            <button onClick={toggleSidebar} className="text-white hidden md:block" title='Expand Sidebar'>
                                <i className="bi bi-caret-right-square"></i>
                            </button> :
                            <button onClick={toggleSidebar} className="text-white hidden md:block" title='Collapse Sidebar'>
                                <i className="bi bi-caret-left-square"></i>
                            </button>
                            }
                    </div>
                    <div className="middle-section menu-wrapper flex-1 overflow-auto">
                        <div className="label text-white text-base font-medium mb-1">Roles</div>
                        <ul className="list-none flex flex-col gap-3 md:gap-4 2xl:gap-6">
                            <li>
                                <Link to={'/admin/growth-engine'} className="admin-sidebar-link">
                                    <img src={MarketingManagerIcon} alt="Growth Engine" className={`${isCollapsed ? 'mx-auto' : ''}`} /> {!isCollapsed && <span className='menu-title'>Growth Engine</span>}
                                </Link>
                            </li>
                            <li>
                                <Link to={'#'} className="admin-sidebar-link">
                                    <img src={PublicistIcon} alt="Publicist" className={`${isCollapsed ? 'mx-auto' : ''}`} /> {!isCollapsed && <span className='menu-title'>Publicist</span>}
                                </Link>
                            </li>
                            <li>
                                <Link to={'#'} className="admin-sidebar-link">
                                    <img src={ArtistIcon} alt="Artist Manager" className={`${isCollapsed ? 'mx-auto' : ''}`} /> {!isCollapsed && <span className='menu-title'>Artist Manager</span>}</Link>
                            </li>
                            <li>
                                <Link to={'#'} className="admin-sidebar-link">
                                    <img src={AudioIcon} alt="Audio Engineer" className={`${isCollapsed ? 'mx-auto' : ''}`} /> {!isCollapsed && <span className='menu-title'>Audio Engineer</span>}
                                </Link>
                            </li>
                            <li>
                                <Link to={'#'} className="admin-sidebar-link">
                                    <img src={CreatorIcon} alt="Content Creator" className={`${isCollapsed ? 'mx-auto' : ''}`} /> {!isCollapsed && <span className='menu-title'>Content Creator</span>}
                                </Link>
                            </li>
                            <li>
                                <Link to={'#'} className="admin-sidebar-link">
                                    <img src={AnalystIcon} alt="Data Analyst" className={`${isCollapsed ? 'mx-auto' : ''}`} /> {!isCollapsed && <span className='menu-title'>Data Analyst</span>}
                                </Link>
                            </li>
                            <li>
                                <Link to={'#'} className="admin-sidebar-link">
                                    <img src={ProfessorUIcon} alt="Content Creator" className={`${isCollapsed ? 'mx-auto' : ''}`} /> {!isCollapsed && <span className='menu-title'>ProfessorU</span>}
                                </Link>
                            </li>
                            <li>
                                <Link to={'#'} className="admin-sidebar-link">
                                    <img src={AmplitudeIcon} alt="Content Creator" className={`${isCollapsed ? 'mx-auto' : ''}`} /> {!isCollapsed && <span className='menu-title'>Amplitude AI</span>}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="bottom-section">
                        <div className="btn-wrapper">
                            <button type='button' className={`bg-theme_clr1 text-sm text-white hover:text-theme_clr2 font-medium flex items-center gap-2 w-full capitalize transition-all drop-shadow-[0_21px_27px_rgba(96,60,255,0.48)] mb-3 md:mb-4 2xl:mb-7 ${isCollapsed ? 'justify-center rounded-xl' : 'justify-between rounded-full'} px-5 py-2 md:py-3 2xl:py-[15px]`}>
                                {!isCollapsed && <span className='menu-title'>Add Members</span>} <i className="bi bi-plus-circle text-[20px]"></i>
                            </button>
                            <button type='button' className={`sidebar-pro-btn bg-gradient-to-b from-[#F5BA23] from-[0%] to-[#E5A000] to-[93%] border border-white font-jakarta font-extrabold text-sm text-white hover:text-theme_clr2 flex items-center gap-2 w-full uppercase transition-all drop-shadow-[0_21px_27px_rgba(96,60,255,0.48)] ${isCollapsed ? 'justify-center rounded-xl' : 'justify-between rounded-full'} px-5 py-2 md:py-3 2xl:py-[15px]`}>
                                {!isCollapsed && <span className='menu-title'>Pro</span>} <img src={StarIcon} alt="Star" className='h-[20px] w-[20px] min-w-[20px]' />
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
        </aside>
    )
}
