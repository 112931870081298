import React from "react";
import ShareIcon from '../../../assets/images/icons/share.svg';

export const StepSix = () => {

    return (
        <div className="font-inter text-theme_clr1 mb-[61px]">
            <div className="heading text-white font-medium text-center mb-[28.5px]">
                <h5 className="text-[22.5px] mb-1">Summary</h5>
                <div className="description text-white text-opacity-80 text-[15px]">
                    Check your ad details and preview of what your ad will look like
                </div>
            </div>
            <div className="info-box bg-white rounded-xl p-4 lg:p-5">
                <div className="flex flex-col lg:flex-row gap-4 xl:gap-5">
                    <div className="left-info lg:w-[50%]">
                        <div className="heading mb-4">
                            <div className="title-name text-base font-medium mb-2">Preview your ad</div>
                            <div className="description font-medium text-theme_clr1 text-[15px] leading-normal">
                                Toggle between platforms to see how your ad will look
                                when it’s live.
                            </div>
                        </div>
                        <div className="radio-wrapper flex gap-2">
                            <div className="input-cover">
                                <input type="radio" className="peer hidden" name="genderStatus" id="FacebookPost" defaultChecked />
                                <label className="text-theme_clr1 text-xs font-medium min-h-[40px] flex items-center justify-center gap-2 peer-checked:bg-theme_clr2 border border-theme_clr1 rounded-lg px-3 py-2" for="FacebookPost">
                                    <i className="bi bi-facebook text-xl leading-none"></i> Facebook
                                </label>
                            </div>
                            <div className="input-cover">
                                <input type="radio" className="peer hidden" name="genderStatus" id="InstaPost" />
                                <label className="text-theme_clr1 text-xs font-medium min-h-[40px] flex items-center justify-center gap-2 peer-checked:bg-theme_clr2 border border-theme_clr1 rounded-lg px-3 py-2" for="InstaPost">
                                    <i className="bi bi-instagram text-xl leading-none"></i> Post
                                </label>
                            </div>
                            <div className="input-cover">
                                <input type="radio" className="peer hidden" name="genderStatus" id="InstaStory" />
                                <label className="text-theme_clr1 text-xs font-medium min-h-[40px] flex items-center justify-center gap-2 peer-checked:bg-theme_clr2 border border-theme_clr1 rounded-lg px-3 py-2" for="InstaStory">
                                    <i className="bi bi-instagram text-xl leading-none"></i> Story
                                </label>
                            </div>
                        </div>
                        <hr className="border-1 border-slate-300 my-4" />
                        <div className="social-post max-w-[335px] w-full border border-black rounded-xl">
                            <div className="top-section p-3">
                                <div className="flex justify-between gap-2">
                                    <div className="left-section flex items-center gap-2">
                                        <div className="user-img h-[35px] w-[35px] rounded-full bg-[#D9D9D9]"></div>
                                        <div className="user-info">
                                            <div className="username text-theme_clr1 text-sm font-semibold capitalize">Karan</div>
                                            <div className="subtitle text-slate-600 text-xs capitalize mt-[2px]">5 January at 05:30PM</div>
                                        </div>
                                    </div>
                                    <div className="right-section flex items-start gap-2">
                                        <button className="text-black"><i className="bi bi-three-dots text-xl leading-tight"></i></button>
                                        <button className="text-black"><i className="bi bi-x-lg text-xl leading-tight"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="middle-section px-3">
                                <div className="description text-black text-xs font-medium mt-1 mb-2">Loirem ipsum is simply dummy text.</div>
                                <div className="profile-picture -mx-3">
                                    <img src={require('../../../assets/images/Aditya-Ojha.png')} alt="User" className="block h-[335px] w-full object-cover" />
                                </div>
                            </div>
                            <div className="bottom-section p-3">
                                <div className="flex items-start justify-between gap-2">
                                    <div className="user-info w-[calc(100%-110px)]">
                                        <div className="song-name text-black text-xs font-medium truncate mb-1">Dummy American song name here</div>
                                        <div className="song-name text-black text-xs truncate mb-1">Dummy American song name here</div>
                                        <div className="subtitle text-xs truncate">Singer: Karan</div>
                                    </div>
                                    <button className="btn btn-custom text-nowrap !rounded-md !px-3">Listen Now</button>
                                </div>
                                <hr className="border-gray-400 my-3" />
                                <div className="action-wrapper flex items-center justify-between gap-4 px-5">
                                    <button className="text-[#99989F] text-[12px] font-medium flex items-center">
                                        <i className="bi bi-hand-thumbs-up-fill mr-1"></i>Like
                                    </button>
                                    <button className="text-[#99989F] text-[12px] font-medium flex items-center">
                                        <i className="bi bi-chat-fill mr-1"></i>Comment
                                    </button>
                                    <button className="text-[#99989F] text-[12px] font-medium flex items-center">
                                        <img src={ShareIcon} alt="ShareIcon" className="block mr-1" />Share
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right-info lg:w-[50%]">
                        <div className="heading mb-4">
                            <div className="title-name text-base font-medium mb-2">Check the details</div>
                            <div className="description font-medium text-theme_clr1 text-[15px] leading-normal">
                                Confirm your selections before payment.
                            </div>
                        </div>
                        <div className="bg-[#F7FAFC] border border-black rounded-xl mb-4 p-4">
                            <div className="item text-base font-medium capitalize flex justify-between gap-3 mb-5">
                                <span className="text-theme_clr1">Goal</span>
                                <span className="text-theme_clr1 text-right">link clicks</span>
                            </div>
                            <div className="item text-[14.5px] font-medium capitalize flex justify-between gap-3 mb-5">
                                <span className="text-theme_clr1">Start date</span>
                                <span className="text-[#99989F] text-right">18/12/24 at 19:15 PM</span>
                            </div>
                            <div className="item text-[14.5px] font-medium capitalize flex justify-between gap-3 mb-5">
                                <span className="text-theme_clr1">End date</span>
                                <span className="text-[#99989F] text-right">18/12/24 at 23:45 PM</span>
                            </div>
                            <div className="item text-[14.5px] font-medium capitalize flex justify-between gap-3 mb-5">
                                <span className="text-theme_clr1">Budget</span>
                                <span className="text-[#99989F] text-right">$10</span>
                            </div>
                            <div className="item text-[14.5px] font-medium capitalize flex justify-between gap-3 mb-5">
                                <span className="text-theme_clr1">Audience</span>
                                <span className="text-[#99989F] text-right">All Genders, 18-34</span>
                            </div>
                            <div className="item text-[14.5px] font-medium capitalize flex justify-between gap-3 mb-5">
                                <span className="text-theme_clr1">Locations</span>
                                <span className="text-[#99989F] text-right">Sydney, Chicago, Mexico City, London, Los Angeles, New York, São Paulo</span>
                            </div>
                            <div className="item text-[14.5px] font-medium capitalize flex justify-between gap-3 mb-5">
                                <span className="text-theme_clr1">Interests</span>
                                <span className="text-[#99989F] text-right">Hip hop music, Future (rapper), Nike, Inc., Drake (rapper), Adidas, Pop music, Jewelry, Apple, Walt Disney, SoundCloud</span>
                            </div>
                            <div className="item text-[14.5px] font-medium capitalize flex justify-between gap-3 mb-5">
                                <span className="text-theme_clr1">Platform</span>
                                <span className="text-[#99989F] text-right">Facebook, Instagram</span>
                            </div>
                        </div>

                        <div className="payment-option mb-4">
                            <div className="title-name text-[22.69px] font-medium mb-2">Payment options</div>
                            <div className="bg-[#F7FAFC] border border-black rounded-xl p-4">
                                <div className="text-xs font-medium flex items-center gap-x-4 gap-y-1 mb-1">
                                    <span className="title">Unlimited campaigns with UpNComer</span>
                                    <span className="flex gap-4">
                                        <span className="bg-[#FF3D00] text-white text-opacity-70 border border-theme_clr1 rounded-full px-2 py-[2.5px]">PRO</span>
                                        <span className="bg-[#FFC107] text-[#FF3D00] border border-theme_clr1 rounded-full px-2 py-[2.5px]">ELITE</span>
                                    </span>
                                </div>
                                <div className="description text-xs font-medium mb-2">Unlimited growth campaigns when you sign up to Pro and Elite plans</div>
                                <button className="bg-theme_clr2 text-white text-[14.88px] font-medium rounded-[4px] px-3 py-1">Subcribe to Save</button>
                            </div>
                        </div>

                        <div className="bg-theme_clr1 text-white rounded-xl p-4">
                            <div className="text-xs font-medium mb-2">First-time campaign</div>
                            <div className="flex items-center justify-between gap-2">
                                <span className="text-[21.75px]">FREE</span>
                                <span className="text-[21px]"><i className="bi bi-check-circle-fill"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
