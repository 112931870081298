import React, { useState, useEffect } from 'react';
import './Stepper.css';

// Import pages
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { StepThree } from './StepThree';
import { StepFour } from './StepFour';
import { StepFive } from './StepFive';
import { StepSix } from './StepSix';

const Stepper = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const totalSteps = 6;
    const pages = [
        <StepOne />, <StepTwo />, <StepThree />, <StepFour />, <StepFive />,
        <StepSix />
    ];
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentStep]);

    const handleNext = () => {
        if (currentStep < pages.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrevious = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    return (
        <div className="stepper-page">
            <div className="container max-w-full p-0">
                <div className="bg-theme_clr1 px-4 py-6 lg:p-10">
                    <div className="custom-wrapper max-w-[875px] mx-auto mb-[23.5px]">
                        {/* Stepper Progress Bar */}
                        <div className="progressbar-navigation text-center mb-[21px]">
                            <div className="relative max-w-[600px] w-full inline-flex items-center justify-between">
                                {pages.map((_, index) => (
                                    <div key={index} className={`relative w-[30px] md:w-[36px] h-[30px] md:h-[36px] text-white text-sm md:text-base font-medium flex items-center justify-center border rounded-full transition-all z-10 ${index <= currentStep ? 'bg-theme_clr2 border-theme_clr2' : 'bg-theme_clr1 border-white'}`}>{index + 1}</div>
                                ))}

                                {/* Connecting Bars */}
                                <div className={`absolute left-0 top-1/2 transform -translate-y-1/2 w-full h-[1px] bg-gray-300 z-0`}></div>

                                {/* Highlighted Active Line */}
                                <div className={`absolute left-0 top-1/2 transform -translate-y-1/2 h-[2px] ${currentStep === totalSteps ? "bg-theme_clr2" : "bg-theme_clr2"}`}
                                    style={{ width: `calc(${(currentStep / (totalSteps - 1)) * 100}%)` }}
                                // Adjust to handle step completion accurately
                                >
                                </div>
                            </div>
                        </div>

                        {/* Page Content */}
                        <div className="content-wrapper">{pages[currentStep]}</div>
                    </div>

                    {/* Buttons */}
                    <div className="bg-white flex flex-wrap items-center justify-between gap-2 rounded-2xl p-4 xl:p-[18px]">
                        { }
                        <div className="left-sec text-theme_clr1 font-inter font-medium">
                            <div className="text-xs mb-1">Potential reach</div>
                            <div className="text-[14.5px]">12.3M - 14.5M</div>
                        </div>
                        <div className="right-sec flex flex-wrap gap-3 lg:gap-4">
                            <button className="bg-transparent text-theme_clr2 text-[12.3px] font-inter font-medium tracking-[0.037] border border-theme_clr2 rounded-lg disabled:opacity-75 py-[10px] px-4"
                                onClick={handlePrevious}
                                disabled={currentStep === 0}
                            >
                                <i class="bi bi-chevron-left"></i> Back
                            </button>
                            <button className="bg-theme_clr2 text-white text-[12.3px] font-inter font-medium tracking-[0.037] rounded-lg disabled:opacity-75 py-[10px] px-4"
                                onClick={handleNext}
                                disabled={currentStep === pages.length - 1}
                            >
                                Continue <i class="bi bi-chevron-right"></i>
                            </button>
                        </div>
                    </div>

                    {/* Buttons */}
                    {
                        currentStep === 5 ?
                            <div className="bg-theme_clr2 flex flex-wrap items-center justify-between gap-2 rounded-2xl p-4 xl:p-[18px]">
                                <div className="left-sec text-theme_clr1 font-inter font-medium leading-none">
                                    <div className="text-[12.8px]">Total to pay now</div>
                                    <div className="text-base">$0.00</div>
                                    <div className="text-[12.8px]">UpNComer service fee</div>
                                </div>
                                <div className="right-sec flex flex-wrap gap-3 lg:gap-4">
                                    <button className="bg-theme_clr1 text-white text-xs font-inter font-medium tracking-[0.037] border border-white rounded-lg py-[10px] px-4"
                                        onClick={handlePrevious}>
                                        <i class="bi bi-chevron-left"></i> Back
                                    </button>
                                    <button className="bg-white text-theme_clr1 text-xs font-inter font-medium tracking-[0.037] rounded-lg py-[10px] px-4">
                                        Confirm and pay
                                    </button>
                                </div>
                            </div>
                            : ''
                    }

                </div>
            </div>
        </div>
    );
};

export default Stepper;
