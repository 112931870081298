import React from 'react';
import { Link } from 'react-router-dom';
import InstagramIcon from '../assets/images/icons/instagram.svg';
import TwitterIcon from '../assets/images/icons/twitter.svg';
import SpotifyIcon from '../assets/images/icons/spotify.svg';
import YoutubeIcon from '../assets/images/icons/youtube.svg';

export const Footer = () => {
  return (
    <footer className='bg-theme_clr1 max-md:text-center py-[40px] xl:pt-[50px] xl:pb-[30px]'>
      <div className="container custom-container">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[30px] xl:gap-[40px] items-center justify-between">
          <div className="grid-item">
            <div className="logo-section max-w-[200px] md:max-w-[300px] max-md:mx-auto">
              <img src={require('../assets/images/logo.png')} alt="Footer Logo" className='block w-full mb-4 xl:mb-[25px]' />
              <button type='button' className='w-full max-w-[480px] min-h-12 xl:h-[65px] bg-theme_clr2 text-white text-xl xl:text-2xl font-manrope font-bold px-[21px] py-2 lg:py-3 rounded-[12px]'>Join Now</button>
            </div>
          </div>
          <div className="grid-item hidden xl:block"></div>
          <div className="grid-item newsletter-section">
            <div className="label text-white text-lg lg:text-[25px] leading-tight mb-7 xl:mb-11">Sign up to our newsletter for all the latest UpNComer news and features.</div>
            <div className="newsletter-wrapper h-[45px] flex justify-between border rounded-xl overflow-hidden">
              <input type="email" className='w-full bg-transparent lg:text-xl text-white font-medium placeholder:text-white placeholder:font-medium placeholder:uppercase focus:outline-none px-4 md:px-[21px] py-2' placeholder='Email' />
              <button type='button' className='bg-white lg:text-xl font-medium px-[21px] py-1'>SUBMIT</button>
            </div>
          </div>
        </div>
        <div className="menu-wrapper font-inter flex flex-col lg:flex-row flex-wrap items-center justify-between gap-4 md:border-y border-white py-[18px] xl:py-5 mt-5 md:mt-8 lg:mt-11">
          <ul className="list-none flex flex-col md:flex-row flex-wrap items-center justify-between gap-2 md:gap-4 md:divide-x-2">
            <li>
              <Link to={'#'} className="flex-grow text-base xl:text-lg text-white hover:text-theme_clr2 font-medium inline-block uppercase transition-all">Contact</Link>
            </li>
            <li>
              <Link to={'/privacy-policy'} className="flex-grow text-base xl:text-lg text-white hover:text-theme_clr2 font-medium inline-block uppercase transition-all ms-4">Privacy</Link>
            </li>
            <li>
              <Link to={'#'} className="flex-grow text-base xl:text-lg text-white hover:text-theme_clr2 font-medium inline-block uppercase transition-all ms-4">Cookies Policy</Link>
            </li>
            <li>
              <Link to={'#'} className="flex-grow text-base xl:text-lg text-white hover:text-theme_clr2 font-medium inline-block uppercase transition-all ms-4">FAQS</Link>
            </li>
            <li>
              <Link to={'/terms-services'} className="flex-grow text-base xl:text-lg text-white hover:text-theme_clr2 font-medium inline-block uppercase transition-all ms-4">Terms & conditions</Link>
            </li>
          </ul>

          <div className="soical-media-sec flex flex-col md:flex-row flex-wrap items-center gap-4">
            <div className="label text-white text-lg xl:text-[25px] leading-none">Follow Us</div>
            <div className="flex flex-wrap justify-center gap-3 md:gap-4">
              <a href="https://www.instagram.com/upncomer.co/" target="_blank" rel="noopener noreferrer"><img src={InstagramIcon} alt="Instagram" className='block transition-all hover:rotate-[360deg] hover:scale-125 duration-500' /></a>
              <a href="https://x.com/upncomerco" target="_blank" rel="noopener noreferrer"><img src={TwitterIcon} alt="Twitter" className='block transition-all hover:rotate-[360deg] hover:scale-125 duration-500' /></a>
              <a href="https://open.spotify.com/user/3142hxn2i3wyimc3yj25skyerhpu?si=7a3cda9f9b574736" target="_blank" rel="noopener noreferrer"><img src={SpotifyIcon} alt="Spotify" className='block transition-all hover:rotate-[360deg] hover:scale-125 duration-500' /></a>
              <a href="https://www.youtube.com/@upncomer" target="_blank" rel="noopener noreferrer"><img src={YoutubeIcon} alt="Facebook" className='block transition-all hover:rotate-[360deg] hover:scale-125 duration-500' /></a>
            </div>
          </div>
        </div>
        <div className="copyright-section text-[#D7CEC1] text-opacity-80 text-sm flex flex-wrap items-center justify-center md:justify-between gap-4 mt-4 md:mt-7 lg:mt-10">
          <div className="left-sec">UpNComer Technologies LLP is a company registered in British Columbia, Canada.  </div>
          <div className="right-sec">&copy; UpNComer 2024</div>
        </div>
      </div>
    </footer>
  )
}
