const initialState = {
    loading: null,
    artistInfo: {},
    artistStats: {},
    artistChartStats: {}
};

const artistDetails = (state = initialState, action) => {
    switch (action.type) {
        case 'STARTARTIST':
            return {
                ...state,
                loading: true,
            };
        case 'ARTISTDATAFAIL':
            return {
                ...state,
                loading: null,
            };
        case 'ARTISTDATASUCCESS':
            return {
                ...state,
                loading: null,
            };
        case 'GETARTISTDATA':
            return {
                ...state,
                loading: null,
                artistInfo: action.artistInfo
            };
        case 'GETARTISTSTATS':
            if (JSON.stringify(state.artistStats) === JSON.stringify(action.stats)) {
                return state;
            }
            return {
                ...state,
                loading: null,
                artistStats: action.stats
            };
        case 'GETARTISTCHARTSTATS':
            if (JSON.stringify(state.artistChartStats) === JSON.stringify(action.stats)) {
                return state;
            }
            return {
                ...state,
                loading: null,
                artistChartStats: action.stats
            };
        default:
            return state;
    }
};

export default artistDetails;