import React, { useRef, useState } from 'react';
import AuthBackground from '../assets/images/auth-banner.jpg';
import { useSelector, useDispatch } from "react-redux";
import * as actions from '../redux/actions/auth';
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const EnterOtp = () => {
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};
  const [otp, setOtp] = useState(["", "", "", ""]);
  const auth = useSelector(state => state.auth);
  const { loading } = auth;
  const dispatch = useDispatch();


  // Function to handle input change
  const handleInputChange = (e, index) => {
    const { value } = e.target;

    // Update OTP state with the entered digit if it's a number
    if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input if a digit is entered
      if (index < 3) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  // Function to handle backspace
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);

      // Move to the previous input on backspace if empty
      if (index > 0 && !otp[index]) {
        inputRefs[index - 1].current.focus();
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    const otpCode = otp.join("");
    if (otpCode.length === 4) {
      let data = {
        email: email,
        otp: otpCode
      }
      dispatch(actions.verifyAccount(data, navigate, toast));
    } else {
      toast.warning('Please enter a complete 4-digit OTP', {
        position: "top-center",
        theme: "colored",
        hideProgressBar: true
      })
    }
  }
  const handleResendOtp = (e) => {
    e.preventDefault()
    let data = {
      email: email,
      type: 'signup'
    }
    dispatch(actions.resendOtp(data, navigate, toast));
  }
  // console.log(email,'--------',document.cookie)
  // verifyAccount

  return (
    <div className='auth-page h-screen flex items-center justify-center'>
      <ToastContainer />
      <div className="grid grid-cols-1 md:grid-cols-2 h-full w-full">
        <div className="grid-item">
          <div className="left-sec bg-theme_clr1 h-full flex items-center justify-center text-center px-5 py-12 lg:p-[50px] 2xl:p-[69px]">
            <div className="wrapper">
              <div className="logo-wrapper mb-6 md:mb-7 xl:mb-[33px]">
                <img src={require('../assets/images/logo.png')} alt="Logo" className="logo max-w-[145px] 2xl:max-w-[200px] mx-auto" />
                <div className='line-bar bg-[#EDECF2] opacity-[15%] h-[1px] w-[64px] mx-auto'></div>
              </div>
              <form className='font-manrope w-full max-w-[600px] mx-auto'>
                <div className="opt-section mb-8 md:mb-[40px] 2xl:mb-[51px]">
                  <div className='heading text-white text-xl lg:text-[25px] xl:text-[32px] 2xl:text-[54px] font-medium mb-5 md:mb-6 xl:mb-8 leading-tight'>Enter OTP</div>
                  <div className='text-white text-lg md:text-xl 2xl:text-2xl font-bold mb-7'>Enter the 4-digit code sent to your email.</div>
                  <div className="input-cover flex justify-center gap-4">
                    {[0, 1, 2, 3].map((index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength={1}
                        ref={inputRefs[index]}
                        value={otp[index]}
                        className="w-[60px] bg-white text-theme_clr1 text-2xl font-semibold border-2 border-black rounded-lg focus:outline-none text-center px-2 py-[10px]"
                        required
                        onChange={(e) => handleInputChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                      />
                    ))}
                  </div>
                  <div className="text-white text-sm mt-6">Didn't receive a code? Check your spam folder or resend it. <button className='text-theme_clr2 ml-2' onClick={(e) => handleResendOtp(e)}>Resend</button></div>
                </div>

                <div className="flex gap-3">
                  <button type='submit' onClick={() => navigate('/signup')} className='w-full bg-white text-black font-medium flex items-center justify-center gap-[4.5px] rounded-full px-5 py-[11.5px]'>Previous</button>
                  <button type='submit' className='loader-button w-full bg-theme_clr2 text-white font-medium flex items-center justify-center gap-[4.5px] rounded-full px-5 py-[11.5px]' disabled={loading} onClick={(e) => handleSubmit(e)}>
                    {loading ? (
                      <span className="loader"> </span>
                    ) : (
                      ''
                    )}
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="grid-item">
          <div className="right-sec h-full flex items-center justify-center px-5 py-12 lg:p-[50px] 2xl:p-[69px]" style={{ backgroundImage: `url(${AuthBackground})` }}>
            <div className="overlay-wrapper bg-[#ffffff] opacity-[72%] max-w-[500px] font-manrope rounded-3xl mx-auto p-5 lg:p-6 xl:p-8">
              <div className="flex flex-wrap items-center justify-between mb-4">
                <div className="left">
                  <h5 className='text-base xl:text-lg 2xl:text-xl text-[#707070]'>Over</h5>
                  <div className="counter text-[40px] xl:text-[50px] 2xl:text-[67px] font-extrabold leading-tight">3M</div>
                  <div className="stream-on text-base xl:text-lg 2xl:text-xl text-[#707070]">streams on Spotify</div>
                </div>
                <div className="right">
                  <div className='rounded-3xl p-5 lg:p-6' style={{ backgroundColor: 'rgba(30, 215, 96, 0.16)' }}>
                    <img src={require('../assets/images/icons/spotify-icon.png')} alt="Spotify Icon" className='block w-[50px] xl:w-[63px]' />
                  </div>
                </div>
              </div>

              <div className="text-black text-base xl:text-lg 2xl:text-xl font-jakarta mb-6 xl:mb-8">
                "What I love most about UpNComer is the clarity it provides. I can track my progress across campaigns,
                finances, and streams-all in one place. It’s like having a full music team at my fingertips, but with more autonomy."
              </div>
              <div className="author-section flex items-center gap-4">
                <img src={require('../assets/images/karan.png')} alt="Author" className='block w-[60px] 2xl:w-[88px]' />
                <div className="author-info capitalize">
                  <div className="name text-lg xl:text-xl 2xl:text-[22px] xl:mb-1">Karan</div>
                  <div className="title text-base xl:text-lg 2xl:text-xl text-[#707070]">Artist</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
