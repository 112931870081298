import React, { useState } from "react";
import MusicIcon from "../../../assets/images/admin/music.svg";
import ChartIcon from "../../../assets/images/admin/chart.svg";
import ClothsIcon from "../../../assets/images/admin/clothes.svg";

export const StepOne = () => {
    // State to track the selected item
    const [selectedItem, setSelectedItem] = useState(2);

    // Handle item click to toggle selection
    const handleClick = (itemId) => {
        setSelectedItem(itemId);
    };

    return (
        <div className="font-inter">
            <div className="heading text-white font-medium text-center mb-[28.5px]">
                <h5 className="text-[22.5px] mb-1">Set your goal</h5>
                <div className="description text-white text-opacity-80 text-[15px]">
                    Choose a goal below and we’ll use your data to pre-select the desired preferences to achieve the best result.
                    You can also change the targeting if you would rather have more control.
                </div>
            </div>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-[12.5px]">
                <div className={`info-box h-full ${selectedItem === 1 ? "bg-theme_clr2" : "bg-white"} text-theme_clr1 text-[15px] font-medium text-center rounded-xl cursor-pointer p-4 md:p-[18px]`} onClick={() => handleClick(1)}>
                    <div className="icon text-center mb-[19.5px]">
                        <img src={MusicIcon} alt="MusicIcon" className="block mx-auto" />
                    </div>
                    <div className="title-name mb-4">More streams</div>
                    <div className="title-desc">Directly drive fans to stream your music</div>
                </div>
                <div className={`info-box h-full ${selectedItem === 2 ? "bg-theme_clr2" : "bg-white"} text-theme_clr1 text-[15px] font-medium text-center rounded-xl cursor-pointer p-4 md:p-[18px]`} onClick={() => handleClick(2)}>
                    <div className="icon text-center mb-[19.5px]">
                        <i class="bi bi-spotify text-4xl leading-none"></i>
                    </div>
                    <div className="title-name mb-4">Spotify growth</div>
                    <div className="title-desc">Increase your Spotify followers</div>
                </div>
                <div className={`info-box h-full ${selectedItem === 3 ? "bg-theme_clr2" : "bg-white"} text-theme_clr1 text-[15px] font-medium text-center rounded-xl cursor-pointer p-4 md:p-[18px]`} onClick={() => handleClick(3)}>
                    <div className="icon text-center mb-[19.5px]">
                        <i class="bi bi-instagram text-4xl leading-none"></i>
                    </div>
                    <div className="title-name mb-4">Instagram growth</div>
                    <div className="title-desc">Increase your Instagram followers</div>
                </div>
                <div className={`info-box h-full ${selectedItem === 4 ? "bg-theme_clr2" : "bg-white"} text-theme_clr1 text-[15px] font-medium text-center rounded-xl cursor-pointer p-4 md:p-[18px]`} onClick={() => handleClick(4)}>
                    <div className="icon text-center mb-[19.5px]">
                        <i class="bi bi-spotify text-4xl leading-none"></i>
                    </div>
                    <div className="title-name mb-4">Playlist growth</div>
                    <div className="title-desc">Grow your Spotify playlists</div>
                </div>
                <div className={`info-box h-full ${selectedItem === 5 ? "bg-theme_clr2" : "bg-white"} text-theme_clr1 text-[15px] font-medium text-center rounded-xl cursor-pointer p-4 md:p-[18px]`} onClick={() => handleClick(5)}>
                    <div className="icon text-center mb-[19.5px]">
                        <i class="bi bi-youtube text-4xl leading-none"></i>
                    </div>
                    <div className="title-name mb-4">YouTube growth</div>
                    <div className="title-desc">Drive YouTube views for your videos</div>
                </div>
                <div className={`info-box h-full ${selectedItem === 6 ? "bg-theme_clr2" : "bg-white"} text-theme_clr1 text-[15px] font-medium text-center rounded-xl cursor-pointer p-4 md:p-[18px]`} onClick={() => handleClick(6)}>
                    <div className="icon text-center mb-[19.5px]">
                        <img src={ChartIcon} alt="ChartIcon" className="block mx-auto" />
                    </div>
                    <div className="title-name mb-4">Pre-Save Link</div>
                    <div className="title-desc">Promote your smartlink, song, video or website</div>
                </div>
                <div className={`info-box h-full ${selectedItem === 7 ? "bg-theme_clr2" : "bg-white"} text-theme_clr1 text-[15px] font-medium text-center rounded-xl cursor-pointer p-4 md:p-[18px]`} onClick={() => handleClick(7)}>
                    <div className="icon text-center mb-[19.5px]">
                        <svg width="36" height="36" viewBox="0 -5 20 27" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-auto">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9272 2.87437C15.1152 1.04415 12.6495 0.00998664 10.0741 0H10C4.61522 0 0.25 4.36522 0.25 9.75V15C0.25 16.2426 1.25736 17.25 2.5 17.25H4C5.24264 17.25 6.25 16.2426 6.25 15V11.25C6.25 10.0074 5.24264 9 4 9H1.78375C2.17156 4.75177 5.7341 1.49979 10 1.5H10.0628C14.3107 1.51795 17.8457 4.76853 18.2191 9H16C14.7574 9 13.75 10.0074 13.75 11.25V15C13.75 16.2426 14.7574 17.25 16 17.25H17.5C18.7426 17.25 19.75 16.2426 19.75 15V9.75C19.7599 7.17412 18.7442 4.70022 16.9272 2.87437ZM4 10.5C4.41421 10.5 4.75 10.8358 4.75 11.25V15C4.75 15.4142 4.41421 15.75 4 15.75H2.5C2.08579 15.75 1.75 15.4142 1.75 15V10.5H4ZM18.25 15C18.25 15.4142 17.9142 15.75 17.5 15.75H16C15.5858 15.75 15.25 15.4142 15.25 15V11.25C15.25 10.8358 15.5858 10.5 16 10.5H18.25V15Z" fill="#00067e" />
                        </svg>
                    </div>
                    <div className="title-name mb-4">Promote an event</div>
                    <div className="title-desc">Sell tickets to your upcoming shows and events</div>
                </div>
                <div className={`info-box h-full ${selectedItem === 8 ? "bg-theme_clr2" : "bg-white"} text-theme_clr1 text-[15px] font-medium text-center rounded-xl cursor-pointer p-4 md:p-[18px]`} onClick={() => handleClick(8)}>
                    <div className="icon text-center mb-[19.5px]">
                        <img src={ClothsIcon} alt="ClothsIcon" className="block mx-auto" />
                    </div>
                    <div className="title-name mb-4">Sell your merch</div>
                    <div className="title-desc">Increase sales of your merch</div>
                </div>
            </div>
        </div>
    )
}
