import React, { useState } from "react";

export const StepFive = () => {

    const [view, setView] = useState('Imagelisting');
    const showImageList = () => setView('Imagelisting');
    const showUploadSection = () => setView('uploadSection');

    return (
        <div className="font-inter text-theme_clr1 mb-[61px]">
            <div className="heading text-white font-medium text-center mb-[28.5px]">
                <h5 className="text-[22.5px] mb-1">Design your ads</h5>
                <div className="description text-white text-opacity-80 text-[15px]">
                    Choose your ad creative to get started. You can select an image from your Facebook account or upload images from your device.
                </div>
            </div>
            <div className="info-box bg-white rounded-xl p-4 lg:p-5">
                <div className="flex flex-col lg:flex-row gap-4 xl:gap-5">
                    <div className="left-info lg:w-[50%]">
                        <div className="title-name text-[22.69px] font-medium mb-2">Choose your image</div>
                        <div className="description font-medium text-theme_clr1 text-[15px] leading-tight">
                            Choose your ad creative to get started. You can select
                            an image from your Facebook account or upload images
                            from your device.
                        </div>
                    </div>
                    <div className="right-info lg:w-[50%]">
                        <div className="btn-wrapper flex flex-wrap gap-2 mb-3">
                            <button onClick={showImageList} className={`text-theme_clr2 text-xs font-medium border-2 border-theme_clr2 rounded-lg px-[13px] py-[11.5px] ${view === 'Imagelisting' ? 'bg-theme_clr1' : 'bg-white'}`}>Facebook</button>
                            <button onClick={showUploadSection} className={`text-theme_clr2 text-xs font-medium border-2 border-theme_clr2 rounded-lg px-[13px] py-[11.5px] ${view === 'uploadSection' ? 'bg-theme_clr1' : 'bg-white'}`}>Upload</button>
                        </div>

                        <hr className="border-1 border-slate-300 my-3" />

                        {view === 'Imagelisting' && (
                            <>
                                <div className="social-img-wrapper pb-2">
                                    <div className="btn-wrapper text-right leading-none mb-3">
                                        <button type="button" className="leading-none"><i className="bi bi-arrow-clockwise text-xl leading-none"></i></button>
                                    </div>
                                    <div className="images-list grid grid-cols-3 gap-3 lg:gap-4 max-h-[408px] overflow-auto">
                                        <img src={require("../../../assets/images/admin/social-img1.png")} alt="Social" className="block w-full" />
                                        <img src={require("../../../assets/images/admin/social-img2.png")} alt="Social" className="block w-full" />
                                        <img src={require("../../../assets/images/admin/social-img3.png")} alt="Social" className="block w-full" />
                                        <img src={require("../../../assets/images/admin/social-img4.png")} alt="Social" className="block w-full" />
                                        <img src={require("../../../assets/images/admin/social-img5.png")} alt="Social" className="block w-full" />
                                        <img src={require("../../../assets/images/admin/social-img6.png")} alt="Social" className="block w-full" />
                                        <img src={require("../../../assets/images/admin/social-img7.png")} alt="Social" className="block w-full" />
                                        <img src={require("../../../assets/images/admin/social-img8.png")} alt="Social" className="block w-full" />
                                        <img src={require("../../../assets/images/admin/social-img9.png")} alt="Social" className="block w-full" />
                                    </div>
                                </div>

                                <hr className="border-1 border-slate-300 my-3" />

                                <div className="social-img-wrapper">
                                    <div className="btn-wrapper text-right leading-none mb-3">
                                        <button type="button" className="leading-none"><i className="bi bi-arrow-clockwise text-xl leading-none"></i></button>
                                    </div>
                                    <div className="images-list grid grid-cols-3 gap-3 lg:gap-4 max-h-[408px] overflow-auto">
                                        <img src={require("../../../assets/images/admin/social-img1.png")} alt="Social" className="block w-full" />
                                        <img src={require("../../../assets/images/admin/social-img2.png")} alt="Social" className="block w-full" />
                                        <img src={require("../../../assets/images/admin/social-img3.png")} alt="Social" className="block w-full" />
                                        <img src={require("../../../assets/images/admin/social-img4.png")} alt="Social" className="block w-full" />
                                        <img src={require("../../../assets/images/admin/social-img5.png")} alt="Social" className="block w-full" />
                                        <img src={require("../../../assets/images/admin/social-img6.png")} alt="Social" className="block w-full" />
                                        <img src={require("../../../assets/images/admin/social-img7.png")} alt="Social" className="block w-full" />
                                        <img src={require("../../../assets/images/admin/social-img8.png")} alt="Social" className="block w-full" />
                                        <img src={require("../../../assets/images/admin/social-img9.png")} alt="Social" className="block w-full" />
                                    </div>
                                </div>
                            </>
                        )}



                        {view === 'uploadSection' && (
                            <div className="input-cover my-6">
                                <input type="file" className="w-full bg-theme_clr1 text-white text-[15px] border-2 border-theme_clr1 rounded-xl leading-tight p-[13px]" placeholder="Link to ad" />
                            </div>
                        )}

                    </div>
                </div>

                <hr className="border-black my-4" />

                <div className="flex flex-col lg:flex-row gap-4 xl:gap-5">
                    <div className="left-info lg:w-[50%]">
                        <div className="title-name text-[22.69px] font-medium mb-2">Fill in your ad details</div>
                        <div className="description font-medium text-theme_clr1 text-[15px] leading-tight">
                            Customise your ad text.
                        </div>
                    </div>
                    <div className="right-info lg:w-[50%]">
                        <form action="">
                            <div className="input-cover border-2 border-theme_clr1 rounded-xl mb-2 p-[13px] pb-1">
                                <textarea rows={5} className="w-full min-h-[110px] block text-theme_clr1 placeholder:text-theme_clr1 text-[15px] font-medium focus:outline-none" placeholder="Primary Text (what is your ad about)"></textarea>
                                <div className="text-theme_clr1 text-xs font-medium text-right mt-1">0/200</div>
                            </div>
                            <div className="input-cover mb-2">
                                <input type="link" className="w-full text-theme_clr1 placeholder:text-theme_clr1 text-[15px] border-2 border-theme_clr1 rounded-xl leading-tight p-[13px]" placeholder="Link to ad" />
                            </div>
                            <div className="input-cover mb-2">
                                <input type="link" className="w-full text-theme_clr1 placeholder:text-theme_clr1 text-[15px] border-2 border-theme_clr1 rounded-xl leading-tight p-[13px]" placeholder="Link Headline (Grab their attention)" />
                            </div>
                            <div className="input-cover">
                                <input type="link" className="w-full text-theme_clr1 placeholder:text-theme_clr1 text-[15px] border-2 border-theme_clr1 rounded-xl leading-tight p-[13px]" placeholder="Link Description (Why should people visit)" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
