import axios from "../../axios-config";

const handleError = (toast, error) => {
    const message = error.response?.data?.message || error.message;
    const status = error.response?.status;

    if ([400, 401, 403, 500].includes(status)) {
        toast.error(message, {
            position: "top-center",
            theme: "colored",
            hideProgressBar: true,
        });
    } else {
        toast.error(message, {
            position: "top-center",
            theme: "colored",
            hideProgressBar: true,
        });
    }
};

export const startArtistData = () => {
    return {
        type: 'STARTARTIST'
    };
}

export const artistDataFail = () => {
    return {
        type: 'ARTISTDATAFAIL'
    }
}
export const artistDataSuccess = () => {
    return {
        type: 'ARTISTDATASUCCESS'
    }
}

export const artistData = (details) => {
    return {
        type: 'GETARTISTDATA',
        artistInfo: details
    }
}


export const getArtistInfo = (toast) => {
    return async dispatch => {
        dispatch(startArtistData());
        try {
            const authCode = "Bearer " + localStorage.getItem('authToken');
            const response = await axios.get('/user/v1/get-artist-info', {
                headers: {
                    Authorization: authCode
                }
            });

            dispatch(artistData(response.data.data));

        } catch (error) {
            dispatch(artistDataFail());
            if (error.response) {
                if ([400, 401, 403, 500].includes(error.response.status)) {
                    toast.error(error.response.data.message, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                    });
                }
            } else {
                toast.error(error.message, {
                    position: "top-center",
                    theme: "colored",
                    hideProgressBar: true,
                });
            }
        }
    };
};

export const artistStats = (details) => {
    return {
        type: 'GETARTISTSTATS',
        stats: details
    }
}

export const getArtistStats = (toast,queryParams) => {
    return async (dispatch) => {
        dispatch(startArtistData());
        try {
            const authCode = "Bearer " + localStorage.getItem('authToken');
            const response = await axios.get('/user/v1/get-artist-stats', {
                headers: { Authorization: authCode },
                params: queryParams
            });
            dispatch(artistStats(response.data.data));
        } catch (error) {
            dispatch(artistDataFail());
            handleError(toast, error);
        }
    };
};

export const artistChartStats = (details) => {
    return {
        type: 'GETARTISTCHARTSTATS',
        stats: details
    }
}

export const getArtistChartStats = (toast,queryParams) => {
    return async (dispatch) => {
        dispatch(startArtistData());
        try {
            const authCode = "Bearer " + localStorage.getItem('authToken');
            const response = await axios.get('/user/v1/get-artist-chart-stats', {
                headers: { Authorization: authCode },
                params: queryParams
            });
            dispatch(artistChartStats(response.data.data));
        } catch (error) {
            dispatch(artistDataFail());
            handleError(toast, error);
        }
    };
};

