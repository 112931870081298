import React, { useState } from "react";
import MusicIcon from "../../../assets/images/admin/music.svg";

export const StepFour = () => {
    const [ageRange, setAgeRange] = useState(34);
    const handleAgeChange = (event) => {
        setAgeRange(event.target.value);
    };

    const LocationTargets = ["Sydney", "Chicago", "Mexico City", "London", "Los Angeles", "New York", "São Paulo"]
    const InterestTargets = ["Hip hop music", "Future (rapper)", "Nike, Inc.", "Drake (rapper)", "Adidas", "Pop music", "Jewelry", "Apple", "Walt Disney", "SoundCloud"]
    const InterestTargets2 = [
        "Travis Scott (musician)", 
        "Spotify (streaming service)", 
        "Disneyland (theme park)", 
        "Popular music (music)", 
        "Fashion accessories (accessories)", 
        "Kanye West (musical artist)", 
        "Apple Music (streaming service)", 
        "Walt Disney World (theme park)", 
        "Post Malone (musical artist)", 
        "Rapping (music)"
    ]

    return (
        <div className="font-inter text-theme_clr1 mb-[61px]">
            <div className="heading text-white font-medium text-center mb-[28.5px]">
                <h5 className="text-[22.5px] mb-1">Audience targeting</h5>
                <div className="description text-white text-opacity-80 text-[15px]">Choose who you want your ad campaign to be shown to</div>
            </div>
            <div className="info-box bg-white rounded-xl p-4 lg:p-5">
                <div className="flex flex-col lg:flex-row gap-4 xl:gap-5">
                    <div className="left-info lg:w-[50%]">
                        <div className="title-name text-[22.69px] font-medium mb-2">Demographic targeting</div>
                        <div className="description font-medium text-theme_clr1 text-[15px] leading-tight">
                            Choose the target demographics for your ad campaign.
                        </div>
                    </div>
                    <div className="right-info lg:w-[50%]">
                        <div className="bg-white border-2 border-theme_clr1 rounded-2xl mb-2 p-4">
                            <div className="title text-[15.25px] font-medium mb-4">Gender</div>
                            <div className="radio-wrapper flex gap-1">
                                <div className="input-cover w-full">
                                    <input type="radio" className="peer hidden" name="genderStatus" id="All" defaultChecked />
                                    <label className="text-white text-sm font-medium min-h-[40px] flex items-center justify-center gap-1 bg-theme_clr1 peer-checked:bg-theme_clr2 rounded-xl px-4 py-2" for="All">
                                        <i className="bi bi-gender-female leading-none -rotate-180"></i> All
                                    </label>
                                </div>
                                <div className="input-cover w-full">
                                    <input type="radio" className="peer hidden" name="genderStatus" id="Male" />
                                    <label className="text-white text-sm font-medium min-h-[40px] flex items-center justify-center gap-1 bg-theme_clr1 peer-checked:bg-theme_clr2 rounded-xl px-4 py-2" for="Male">
                                        <i className="bi bi-gender-male leading-none"></i> Male
                                    </label>
                                </div>
                                <div className="input-cover w-full">
                                    <input type="radio" className="peer hidden" name="genderStatus" id="Female" />
                                    <label className="text-white text-sm font-medium min-h-[40px] flex items-center justify-center gap-1 bg-theme_clr1 peer-checked:bg-theme_clr2 rounded-xl px-4 py-2" for="Female">
                                        <i className="bi bi-gender-female leading-none"></i> Female
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white border-2 border-theme_clr1 rounded-2xl p-4">
                            <div className="title text-[15.25px] font-medium mb-4">Age range</div>
                            <div className="price-range mb-5">
                                <input type="range" min="18" max="1000" step="1" value={ageRange}
                                    onChange={handleAgeChange}
                                    className="custom-range-slider w-full"
                                />
                                <div className="text-xs font-medium flex justify-between gap-2">
                                    <span>{0}</span> {/* Minimum value */}
                                    <span>{ageRange}</span> {/* Display the selected value */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="border-black my-4" />

                <div className="flex flex-col lg:flex-row gap-4 xl:gap-5">
                    <div className="left-info lg:w-[50%]">
                        <div className="title-name text-[22.69px] font-medium mb-2">Location targeting</div>
                        <div className="description font-medium text-theme_clr1 text-[15px] leading-tight">
                            Choose the target locations for your ad campaign.
                        </div>
                    </div>
                    <div className="right-info lg:w-[50%]">
                        <div className="bg-white border-2 border-theme_clr1 rounded-2xl mb-4 p-4">
                            <div className="flex gap-5">
                                <div className="icon pt-1">
                                    <img src={MusicIcon} alt="MusicIcon" className="block w-[24px]" />
                                </div>
                                <div className="info">
                                    <div className="text-[15.25px] font-medium capitalize leading-tight">Primary Music Markets</div>
                                    <div className="subtitle text-sm uppercase">(USA, JPN, UK, GER, FRA, CAN, AUS, NLD)</div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white border-2 border-theme_clr1 rounded-2xl mb-4 p-4">
                            <div className="flex items-center gap-5">
                                <i class="bi bi-globe-americas text-xl leading-none"></i>
                                <div className="text-[15.25px] font-medium capitalize leading-tight">Global</div>
                            </div>
                        </div>
                        <div className="bg-white border-2 border-theme_clr1 rounded-2xl p-4">
                            <div className="flex items-center gap-5 mb-2">
                                <i class="bi bi-geo-alt-fill text-xl leading-none"></i>
                                <div className="text-[15.25px] font-medium capitalize leading-tight">Select custom location</div>
                            </div>
                            <div className="text-xs font-medium mb-4">Choose specific target locations for your ad campaign</div>
                            <div className="search-wrapper">
                                <div className="search-box bg-theme_clr1 rounded-full px-4 py-2 flex items-center gap-2 relative">
                                    <i className="bi bi-search text-white"></i>
                                    <input type="search" placeholder="Search location" className="w-full bg-transparent text-white placeholder:text-white text-sm focus:outline-none" />
                                </div>

                                <div className="search-suggestion flex flex-wrap gap-2 mt-4">
                                    {LocationTargets.map((location, index) =>
                                        <div key={index} className="bg-gradient-to-b from-[#FA6138] to-[#FF3D00] text-white text-[13px] font-roboto flex items-center gap-2 capitalize rounded-lg leading-tight pl-4 p-[8px]">
                                            {location} <i className="bi bi-x text-base leading-none cursor-pointer"></i>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="border-black my-4" />

                <div className="flex flex-col lg:flex-row gap-4 xl:gap-5">
                    <div className="left-info lg:w-[50%]">
                        <div className="title-name text-[22.69px] font-medium mb-2">Interest targeting</div>
                        <div className="description font-medium text-theme_clr1 text-[15px] leading-tight">
                            Your ad will be served to fans who are interested in the
                            topics listed here. Only select relevant interests so
                            targeting is as precise as possible.
                        </div>
                    </div>
                    <div className="right-info lg:w-[50%]">
                        <div className="bg-white border-2 border-theme_clr1 rounded-2xl p-4">
                            <div className="search-box bg-theme_clr1 rounded-full px-4 py-2 flex items-center gap-2 relative mb-4">
                                <i className="bi bi-search text-white"></i>
                                <input type="search" placeholder="Search interest targets" className="w-full bg-transparent text-white placeholder:text-white text-sm focus:outline-none" />
                            </div>
                            <div className="interest-suggestion mb-4">
                                <div className="text-[15.25px] font-medium capitalize leading-tight">Selected interest targets</div>
                                <div className="flex flex-wrap gap-2 mt-4">
                                    {InterestTargets.map((targets, index) =>
                                        <div key={index} className="bg-gradient-to-b from-[#FA6138] to-[#FF3D00] text-white text-[13px] font-roboto flex items-center gap-2 capitalize rounded-lg leading-tight pl-4 p-[8px]">
                                            {targets} <i className="bi bi-x text-base leading-none cursor-pointer"></i>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="interest-suggestion">
                                <div className="text-[15.25px] font-medium capitalize leading-tight">More suggested interest targets</div>
                                <div className="flex flex-wrap gap-2 mt-4">
                                    {InterestTargets2.map((targets2, idx) =>
                                        <div key={idx} className="bg-white border border-theme_clr1 text-theme_clr1 text-[13px] font-roboto flex items-center gap-2 capitalize rounded-lg leading-tight pl-4 p-[8px]">
                                            {targets2} <i className="bi bi-x text-base leading-none cursor-pointer"></i>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
