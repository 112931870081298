import React from 'react';
import SpotifyIcon from '../../assets/images/admin/Spotify.svg';
import AppleIcon from '../../assets/images/admin/Apple.svg';
import StartIcon from '../../assets/images/admin/Star.svg';
import AmazonIcon from '../../assets/images/admin/Amazon.svg';
import GroupIcon from '../../assets/images/admin/Group-line.svg';
import StreamIcon from '../../assets/images/icons/stream.svg';
import FanbaseIcon from '../../assets/images/icons/fanbase.svg';
import BestTrack from '../../assets/images/icons/best-track.svg';
import Listeners from '../../assets/images/icons/listeners.svg';
import FollowersIcon from '../../assets/images/icons/followers.svg';
import InstaIcon from '../../assets/images/admin/Instagram.png';
import TiktokIcon from '../../assets/images/admin/Tiktok.png';
import YoutubeIcon from '../../assets/images/admin/Youtube.png';
import StarOutlinedIcon from '../../assets/images/admin/Star-outlined.svg';
import SpotifyLineIcon from '../../assets/images/admin/spotify-lines.svg';
import AreaChart from './charts/AreaChart';

export const DataAnalyst = () => {
    return (
        <div className='DataAnalyst-page py-5'>
            <div className="container max-w-full">
                <div className="card bg-gradient-to-r from-theme_clr1 to-theme_clr2 rounded-xl mb-5 p-4 lg:p-5 pb-9">
                    <h5 className='text-white text-[23px] font-manrope font-bold leading-tight mb-[11px]'>Your Data Analyst</h5>
                    <div className='text-white text-xl font-jakarta'>Unlock insights into your music performance and audience data across platforms.</div>
                </div>

                {/* Performance Section */}
                <div className="performance-section bg-theme_clr1 rounded-xl mb-5 p-4 lg:px-5 pt-[26px] pb-[30px]">
                    <div className="header-section flex flex-wrap items-center justify-between gap-2 mb-[10px] md:mb-[21px]">
                        <div className="flex flex-wrap gap-6">
                            <select name="" id="" className='bg-transparent text-white text-base font-manrope border-0 focus:outline-none'>
                                <option value="0">All time performance</option>
                                <option value="1">Monthly performance</option>
                                <option value="2">Quarterly performance</option>
                                <option value="3">Yearly performance</option>
                            </select>
                            <div className="icons flex items-center gap-6">
                                <img src={SpotifyIcon} alt="Spotify" className='block' />
                                <img src={AppleIcon} alt="Apple" className='block' />
                                <img src={StartIcon} alt="Star" className='block' />
                                <img src={AmazonIcon} alt="Amazon" className='block' />
                                <img src={GroupIcon} alt="Group Line" className='block' />
                            </div>
                        </div>
                        <button type='button' className='btn-custom3'>View Streaming Analytics</button>
                    </div>
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 2xl:gap-[22px] flex-nowrap overflow-auto">
                        <div className="grid-item">
                            <div className="info-box h-full bg-white font-manrope flex flex-col justify-between gap-2 rounded-xl p-4 2xl:p-5">
                                <div className="icon mb-1">
                                    <img src={StreamIcon} alt="Streams" />
                                </div>
                                <div className="chart-wrapper" style={{ marginLeft: '-10px' }}>
                                    <AreaChart />
                                </div>
                                <div className="cover">
                                    <div className="text-theme_clr1 text-[12.26px] font-bold mb-[2px]">10.3M</div>
                                    <div className="text-theme_clr1 text-[12.8px]">Streams</div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-white font-manrope flex flex-col justify-between gap-2 rounded-xl p-4 2xl:p-5">
                                <div className="icon mb-1">
                                    <img src={FanbaseIcon} alt="Fanbase" />
                                </div>
                                <div className="chart-wrapper" style={{ marginLeft: '-10px' }}>
                                    <AreaChart />
                                </div>
                                <div className="cover">
                                    <div className="text-theme_clr1 text-[12.26px] font-bold mb-[2px]">50.0k</div>
                                    <div className="text-theme_clr1 text-[12.8px]">Fanbase</div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-white font-manrope flex flex-col justify-between gap-2 rounded-xl p-4 2xl:p-5">
                                <div className="icon">
                                    <img src={BestTrack} alt="Best Track" />
                                    <div className="text-black text-[12.26px] font-bold mt-2">Right Now V2</div>
                                </div>
                                <div className="chart-wrapper">
                                    {/* <AreaChart /> */}
                                    <img src={require('../../assets/images/best-track2.jpg')} alt="Best Track" className='block w-[400px] mx-auto' />
                                </div>
                                <div className="cover">
                                    <div className="text-theme_clr1 text-[12.26px] font-bold mb-[2px]">7.3M</div>
                                    <div className="text-theme_clr1 text-[12.8px]">Best Performing Track</div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-white font-manrope flex flex-col justify-between gap-2 rounded-xl p-4 2xl:p-5">
                                <div className="icon mb-1">
                                    <img src={Listeners} alt="Listeners" />
                                </div>
                                <div className="chart-wrapper" style={{ marginLeft: '-10px' }}>
                                    <AreaChart />
                                </div>
                                <div className="cover">
                                    <div className="text-theme_clr1 text-[12.26px] font-bold mb-[2px]">4.4M</div>
                                    <div className="text-theme_clr1 text-[12.8px]">Listeners</div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item sm:col-span-2 xl:col-span-1">
                            <div className="info-box h-full bg-white font-manrope flex flex-col justify-between gap-2 rounded-xl p-4 2xl:p-5">
                                <div className="icon mb-1">
                                    <img src={FollowersIcon} alt="FollowersIcon" />
                                </div>
                                <div className="chart-wrapper" style={{ marginLeft: '-10px' }}>
                                    <AreaChart />
                                </div>
                                <div className="cover">
                                    <div className="text-theme_clr1 text-[12.26px] font-bold mb-[2px]">15.0k</div>
                                    <div className="text-theme_clr1 text-[12.8px]">Followers</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Performance Section */}
                <div className="performance-section bg-theme_clr1 rounded-xl mb-5 p-4 lg:px-5 pt-[26px] pb-[30px]">
                    <div className="header-section flex flex-wrap items-center justify-between gap-2 mb-[10px] md:mb-[21px]">
                        <div className="flex flex-wrap gap-6">
                            <select name="" id="" className='bg-transparent text-white text-base font-manrope border-0 focus:outline-none'>
                                <option value="0">All time audience</option>
                                <option value="1">Monthly audience</option>
                                <option value="2">Quarterly audience</option>
                                <option value="3">Yearly audience</option>
                            </select>
                            <div className="icons flex items-center gap-6">
                                <img src={InstaIcon} alt="Instagram" className='block w-[28px]' />
                                <img src={TiktokIcon} alt="Tiktok" className='block w-[34px]' />
                                <img src={YoutubeIcon} alt="Youtube" className='block w-[34px]' />
                            </div>
                        </div>
                        <button type='button' className='btn-custom3'>View Social Analytics</button>
                    </div>
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 2xl:gap-[22px] flex-nowrap overflow-auto">
                        <div className="grid-item">
                            <div className="info-box h-full bg-white font-manrope flex flex-col justify-between gap-2 rounded-xl p-4 2xl:p-5">
                                <div className="icon mb-1">
                                    <img src={FollowersIcon} alt="FollowersIcon" />
                                </div>
                                <div className="chart-wrapper" style={{ marginLeft: '-10px' }}>
                                    <AreaChart />
                                </div>
                                <div className="cover">
                                    <div className="text-theme_clr1 text-[12.26px] font-bold mb-[2px]">15.0K</div>
                                    <div className="text-theme_clr1 text-[12.8px]">Followers</div>
                                </div>
                            </div>

                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-white font-manrope flex flex-col justify-between gap-2 rounded-xl p-4 2xl:p-5">
                                <div className="icon mb-1">
                                    <img src={FanbaseIcon} alt="Fanbase" />
                                </div>
                                <div className="chart-wrapper" style={{ marginLeft: '-10px' }}>
                                    <AreaChart />
                                </div>
                                <div className="cover">
                                    <div className="text-theme_clr1 text-[12.26px] font-bold mb-[2px]">50.0k</div>
                                    <div className="text-theme_clr1 text-[12.8px]">Average Engagement</div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-white font-manrope flex flex-col justify-between gap-2 rounded-xl p-4 2xl:p-5">
                                <div className="icon">
                                    <img src={BestTrack} alt="Best Track" />
                                    <div className="text-black text-[12.26px] font-bold mt-2">Right Now V2</div>
                                </div>
                                <div className="chart-wrapper">
                                    {/* <AreaChart /> */}
                                    <img src={require('../../assets/images/best-track2.jpg')} alt="Best Track" className='block w-[400px] mx-auto' />
                                </div>
                                <div className="cover">
                                    <div className="text-theme_clr1 text-[12.26px] font-bold mb-[2px]">400K Views</div>
                                    <div className="text-theme_clr1 text-[12.8px]">Best Performing Sound</div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-white font-manrope flex flex-col justify-between gap-2 rounded-xl p-4 2xl:p-5">
                                <div className="icon mb-1">
                                    <img src={Listeners} alt="Listeners" />
                                </div>
                                <div className="chart-wrapper" style={{ marginLeft: '-10px' }}>
                                    <AreaChart />
                                </div>
                                <div className="cover">
                                    <div className="text-theme_clr1 text-[12.26px] font-bold mb-[2px]">4.4M</div>
                                    <div className="text-theme_clr1 text-[12.8px]">Interactions</div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item sm:col-span-2 xl:col-span-1">
                            <div className="info-box h-full bg-white font-manrope flex flex-col justify-between gap-2 rounded-xl p-4 2xl:p-5">
                                <div className="icon mb-1">
                                    <img src={StreamIcon} alt="Streams" />
                                </div>
                                <div className="chart-wrapper" style={{ marginLeft: '-10px' }}>
                                    <AreaChart />
                                </div>
                                <div className="cover">
                                    <div className="text-theme_clr1 text-[12.26px] font-bold mb-[2px]">10.3M</div>
                                    <div className="text-theme_clr1 text-[12.8px]">Views</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Grid Listing Section */}
                <div className="performance-section">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                        <div className="grid-item xl:row-span-2">
                            <div className="info-box h-full bg-theme_clr1 text-white flex flex-col justify-between rounded-xl p-4 xl:p-5">
                                <div className="title-info text-[23px] font-bold font-manrope flex items-center gap-2 leading-tight mb-4">
                                    <img src={StarOutlinedIcon} alt="Milestones" className='inline' />Milestones
                                </div>
                                <div className="card-wrapper font-lato mb-3">
                                    <div className="song-card w-full inline-flex items-center rounded-sm overflow-hidden mb-1">
                                        <img src={require('../../assets/images/Aditya-Ojha.png')} alt="ProfessorU" className='w-[60px] min-w-[60px] h-[60px] object-cover' />
                                        <div className="info bg-white text-black truncate px-3 py-2">
                                            <div className="song-name text-[15px] font-semibold capitalize truncate">This is dummy song</div>
                                            <div className="singer-name text-sm capitalize truncate">Karan Thapar</div>
                                        </div>
                                    </div>
                                    <div className="info text-[15px] flex flex-wrap justify-between gap-1">
                                        <div className="left">
                                            <div>Playlisted #5</div>
                                            <div className="date text-[#ABABAB] text-xs uppercase tracking-[2.4px]">FEB 14</div>
                                        </div>
                                        <div className="right">
                                            <span className='flex items-center gap-1 text-[#0F90CC] font-bold capitalize'>Beats & Rhymes <img src={SpotifyLineIcon} alt="Spotify" className='' /></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-wrapper font-lato mb-3">
                                    <div className="song-card w-full inline-flex items-center rounded-sm overflow-hidden mb-1">
                                        <img src={require('../../assets/images/Aditya-Ojha.png')} alt="ProfessorU" className='w-[60px] min-w-[60px] h-[60px] object-cover' />
                                        <div className="info bg-white text-black truncate px-3 py-2">
                                            <div className="song-name text-[15px] font-semibold capitalize truncate">This is dummy song</div>
                                            <div className="singer-name text-sm capitalize truncate">Karan Thapar</div>
                                        </div>
                                    </div>
                                    <div className="info text-[15px] flex flex-wrap justify-between gap-1">
                                        <div className="left">
                                            <div>Charted  #127</div>
                                            <div className="date text-[#ABABAB] text-xs uppercase tracking-[2.4px]">Dec 15</div>
                                        </div>
                                        <div className="right">
                                            <span className='flex items-center gap-1 text-[#0F90CC] font-bold capitalize'>RnB: Singapore <i class="bi bi-apple text-white"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-wrapper font-lato mb-3">
                                    <div className="song-card w-full inline-flex items-center rounded-sm overflow-hidden mb-1">
                                        <img src={require('../../assets/images/Aditya-Ojha.png')} alt="ProfessorU" className='w-[60px] min-w-[60px] h-[60px] object-cover' />
                                        <div className="info bg-white text-black truncate px-3 py-2">
                                            <div className="song-name text-[15px] font-semibold capitalize truncate">This is dummy song</div>
                                            <div className="singer-name text-sm capitalize truncate">Karan Thapar</div>
                                        </div>
                                    </div>
                                    <div className="info text-[15px] flex flex-wrapc justify-between gap-1">
                                        <div className="left">
                                            <div>100k Streams</div>
                                            <div className="date text-[#ABABAB] text-xs uppercase tracking-[2.4px]">Nov 15</div>
                                        </div>
                                        <div className="right">
                                            <span className='flex items-center gap-1 text-[#0F90CC] font-bold capitalize'>Spotify <img src={SpotifyLineIcon} alt="Spotify" className='' /></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-wrapper">
                                    <button type='button' className='btn-custom4'>View Milestones</button>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item xl:row-span-2">
                            <div className="info-box h-full bg-theme_clr1 text-white flex flex-col justify-between rounded-xl p-4 xl:p-5">
                                <div className="title-info text-[23px] font-bold font-manrope flex items-center gap-2 leading-tight mb-4">
                                    <img src={require('../../assets/images/admin/Radio-Play.png')} alt="Radio Play" className='w-[30px] h-[30px]' />Radio Play
                                </div>
                                <div className="card-wrapper font-lato mb-3">
                                    <div className="song-card w-full inline-flex items-center rounded-sm overflow-hidden mb-1">
                                        <img src={require('../../assets/images/Aditya-Ojha.png')} alt="ProfessorU" className='w-[60px] min-w-[60px] h-[60px] object-cover' />
                                        <div className="info bg-white text-black truncate px-3 py-2">
                                            <div className="song-name text-[15px] font-semibold capitalize truncate">This is dummy song</div>
                                            <div className="singer-name text-sm capitalize truncate">Karan Thapar</div>
                                        </div>
                                    </div>
                                    <div className="info text-[15px] flex flex-wrap justify-between gap-1">
                                        <div className="left">
                                            <div>Played on</div>
                                            <div className="date text-[#ABABAB] text-xs uppercase tracking-[2.4px]">Jul 14</div>
                                        </div>
                                        <div className="right flex gap-1">
                                            <span className='text-[#0F90CC] font-bold capitalize'>Darusalam Radio</span>
                                            <span className='text-white'>(Iraq)</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-wrapper font-lato mb-3">
                                    <div className="song-card w-full inline-flex items-center rounded-sm overflow-hidden mb-1">
                                        <img src={require('../../assets/images/Aditya-Ojha.png')} alt="ProfessorU" className='w-[60px] min-w-[60px] h-[60px] object-cover' />
                                        <div className="info bg-white text-black truncate px-3 py-2">
                                            <div className="song-name text-[15px] font-semibold capitalize truncate">This is dummy song</div>
                                            <div className="singer-name text-sm capitalize truncate">Karan Thapar</div>
                                        </div>
                                    </div>
                                    <div className="info text-[15px] flex flex-wrap justify-between gap-1">
                                        <div className="left">
                                            <div>Played on</div>
                                            <div className="date text-[#ABABAB] text-xs uppercase tracking-[2.4px]">Nov 15</div>
                                        </div>
                                        <div className="right flex gap-1">
                                            <span className='text-[#0F90CC] font-bold capitalize'>Fairfield EMS ...</span>
                                            <span className='text-white'>(USA)</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-wrapper font-lato mb-3">
                                    <div className="song-card w-full inline-flex items-center rounded-sm overflow-hidden mb-1">
                                        <img src={require('../../assets/images/Aditya-Ojha.png')} alt="ProfessorU" className='w-[60px] min-w-[60px] h-[60px] object-cover' />
                                        <div className="info bg-white text-black truncate px-3 py-2">
                                            <div className="song-name text-[15px] font-semibold capitalize truncate">This is dummy song</div>
                                            <div className="singer-name text-sm capitalize truncate">Karan Thapar</div>
                                        </div>
                                    </div>
                                    <div className="info text-[15px] flex flex-wrap justify-between gap-1">
                                        <div className="left">
                                            <div>Played on</div>
                                            <div className="date text-[#ABABAB] text-xs uppercase tracking-[2.4px]">Nov 15</div>
                                        </div>
                                        <div className="right flex gap-1">
                                            <span className='text-[#0F90CC] font-bold capitalize'>DCLM Radio Po ...</span>
                                            <span className='text-white'>(Lagos)</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-wrapper">
                                    <button type='button' className='btn-custom4'>View Radio Data</button>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item xl:row-span-2">
                            <div className="info-box h-full bg-theme_clr1 text-white rounded-xl p-4 xl:p-5">
                                <div className="title-info text-[23px] font-bold font-manrope flex items-center gap-2 leading-tight mb-6">
                                    <img src={require('../../assets/images/admin/Amplitude-Info.png')} alt="Amplitude Info" className='w-[30px] h-[30px]' />Amplitude Insight
                                </div>
                                <div className="desc-wrapper flex flex-col gap-6">
                                    <div className="text-center">
                                        <p className='text-sm font-bold mb-2'>‘Right Now V2’ is trending up; consider promoting it further.</p>
                                        <button type='button' className='btn-custom3 w-full ! !py-3'>Run Conversion Campaign</button>
                                    </div>
                                    <div className="text-center">
                                        <p className='text-sm font-bold mb-2'>Get your music heard by fans of your genre.</p>
                                        <button type='button' className='btn-custom3 w-full !py-3'>Submit to Playlists</button>
                                    </div>
                                    <div className="text-center">
                                        <p className='text-sm font-bold mb-2'>Improve Sound Quality with your AI Engineer.</p>
                                        <button type='button' className='btn-custom3 w-full !py-3'>Master a Track</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-theme_clr1 text-white flex flex-col justify-between rounded-xl p-4 xl:p-5">
                                <div className="title-info text-[23px] font-bold font-manrope flex items-center gap-2 leading-tight mb-[11px]">
                                    <img src={StarOutlinedIcon} alt="Star Outlined" className='inline' />Audience Demographics
                                </div>
                                <div className="element-wrapper text-[12.5px] font-manrope">
                                    <div className="flex flex-wrap justify-between gap-2 mb-[11px]">
                                        <div className="title font-bold">Top City<span className='ms-2'>🏙️</span></div>
                                        <div className="value font-normal">Singapore</div>
                                    </div>
                                    <div className="flex flex-wrap justify-between gap-2 mb-[11px]">
                                        <div className="title font-bold">Top Ages<span className='ms-2'>🔞</span></div>
                                        <div className="value font-normal">18 - 24</div>
                                    </div>
                                    <div className="flex flex-wrap justify-between gap-2 mb-[15px]">
                                        <div className="title font-bold">Top Gender<span className='ms-2'>🙎🏽‍♂️</span></div>
                                        <div className="value font-normal">53% Male</div>
                                    </div>
                                </div>
                                <div className="btn-wrapper">
                                    <button type='button' className='btn-custom4'>View Demographics</button>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-theme_clr1 text-white rounded-xl p-4 xl:p-5">
                                <div className="text-[21.56px] font-bold font-manrope mb-4">Popularity Score</div>
                                <div className="info text-[50px] font-semibold leading-tight text-center mb-[17px]">25%</div>
                                <div className="btn-wrapper">
                                    <button type='button' className='btn-custom4'>View Score by Song</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
