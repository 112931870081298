import React, { useState } from "react";
import { format, startOfMonth, endOfMonth, eachDayOfInterval, addMonths, subMonths, isSameDay, isBefore, isAfter } from "date-fns";

export const StepThree = () => {
    const [price, setPrice] = useState(400);
    const handlePriceChange = (event) => {
        setPrice(event.target.value);
    };

    // Date range selection
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    // Get the start and end of the current month
    const startOfCurrentMonth = startOfMonth(currentMonth);
    const endOfCurrentMonth = endOfMonth(currentMonth);

    // Get all days of the current month
    const daysInMonth = eachDayOfInterval({
        start: startOfCurrentMonth,
        end: endOfCurrentMonth,
    });

    // Navigate to next and previous month
    const goToNextMonth = () => setCurrentMonth(addMonths(currentMonth, 1));
    const goToPreviousMonth = () => setCurrentMonth(subMonths(currentMonth, 1));

    // Handle date range selection
    const handleDateClick = (date) => {
        if (!startDate || (startDate && endDate)) {
            setStartDate(date);
            setEndDate(null); // Reset end date if starting a new range
        } else if (startDate && !endDate && date >= startDate) {
            setEndDate(date);
        } else {
            setStartDate(date);
            setEndDate(null); // Reset end date and start a new range if clicked on earlier date
        }
    };

    // Determine if a date is within the selected range
    const isWithinRange = (date) => {
        if (startDate && endDate) {
            return isAfter(date, startDate) && isBefore(date, endDate);
        }
        return false;
    };

    return (
        <div className="font-inter text-theme_clr1 mb-[61px]">
            <div className="heading text-white font-medium text-center mb-[28.5px]">
                <h5 className="text-[22.5px] mb-1">Budget and campaign duration</h5>
                <div className="description text-white text-opacity-80 text-[15px]">Decide on the duration you want to run your ads for, and set your budget</div>
            </div>
            <div className="info-box bg-white rounded-xl p-4 lg:p-5">
                <div className="flex flex-col lg:flex-row gap-4 xl:gap-5">
                    <div className="left-info lg:w-[50%]">
                        <div className="title-name text-[22.69px] font-medium mb-2">Set your budget</div>
                        <div className="description font-medium text-theme_clr1 text-[15px] leading-tight">
                            Drag the slider to set your budget.  This will determine
                            the potential length of your campaign.
                        </div>
                    </div>
                    <div className="right-info lg:w-[50%]">
                        <div className="bg-white border-2 border-theme_clr1 rounded-2xl p-4">
                            <div className="price-range mb-5">
                                <input type="range" min="10" max="1000" step="10" value={price}
                                    onChange={handlePriceChange}
                                    className="custom-range-slider w-full"
                                />
                                <div className="text-xs font-medium flex justify-between gap-2">
                                    <span>${0}</span> {/* Minimum value */}
                                    <span>${price}</span> {/* Display the selected value */}
                                </div>
                            </div>
                            <div className="range-description font-medium">
                                <div className="flex gap-4 mb-5">
                                    <div className="w-full h-[83px] bg-white flex flex-col justify-center text-center border-2 border-theme_clr1 rounded-2xl p-4">
                                        <div className="text-xs">$500</div>
                                        <div className="text-[8px]">Budget</div>
                                    </div>
                                    <div className="w-full h-[83px] bg-white flex flex-col justify-center text-center border-2 border-theme_clr1 rounded-2xl p-4">
                                        <div className="text-xs">100</div>
                                        <div className="text-[8px]">Max days</div>
                                    </div>
                                </div>
                                <div className="text-xs mb-3">We recommend a minimum budget of $100</div>
                                <div className="text-xs font-semibold">NOTE: Please be aware that the amount entered will be in the default currency you have set on your Meta ad account</div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="border-black my-4" />

                <div className="flex flex-col lg:flex-row gap-4 xl:gap-5">
                    <div className="left-info lg:w-[50%]">
                        <div className="title-name text-[22.69px] font-medium mb-2">Set your dates</div>
                        <div className="description font-medium text-theme_clr1 text-[15px] leading-tight">
                            Decide how long you want to run your ads for.
                        </div>
                    </div>
                    <div className="right-info lg:w-[50%]">
                        <div className="bg-white border-2 border-theme_clr1 rounded-2xl p-4">
                            <div className="calendar-wrapper max-w-[312px] w-full text-center mx-auto">
                                {(startDate && endDate) && (
                                    <div className="text-theme_clr1 text-[14.25px] font-medium mb-4">{format(startDate, "MMMM dd, yyyy")} - {format(endDate, "MMMM dd, yyyy")}</div>
                                )}
                                {/* Month Navigation */}
                                <div className="text-[12.8px] font-roboto font-medium flex items-center justify-between mb-2">
                                    <button className="h-[30px] w-[40px]" onClick={goToPreviousMonth}><i className="bi bi-chevron-left"></i></button>
                                    <span>{format(currentMonth, "MMMM yyyy")}</span>
                                    <button className="h-[30px] w-[40px]" onClick={goToNextMonth}><i className="bi bi-chevron-right"></i></button>
                                </div>
                                <div className="grid grid-cols-7 gap-[1px] text-center">
                                    {/* Days of the Week */}
                                    <div className="text-xs font-roboto h-[35px] w-[35px] md:h-[40px] md:w-[40px] flex items-center justify-center">S</div>
                                    <div className="text-xs font-roboto h-[35px] w-[35px] md:h-[40px] md:w-[40px] flex items-center justify-center">M</div>
                                    <div className="text-xs font-roboto h-[35px] w-[35px] md:h-[40px] md:w-[40px] flex items-center justify-center">T</div>
                                    <div className="text-xs font-roboto h-[35px] w-[35px] md:h-[40px] md:w-[40px] flex items-center justify-center">W</div>
                                    <div className="text-xs font-roboto h-[35px] w-[35px] md:h-[40px] md:w-[40px] flex items-center justify-center">T</div>
                                    <div className="text-xs font-roboto h-[35px] w-[35px] md:h-[40px] md:w-[40px] flex items-center justify-center">F</div>
                                    <div className="text-xs font-roboto h-[35px] w-[35px] md:h-[40px] md:w-[40px] flex items-center justify-center">S</div>

                                    {/* Empty days before the first day of the month */}
                                    {Array.from({ length: startOfCurrentMonth.getDay() }).map((_, index) => (
                                        <div key={index} className="p-2"></div>
                                    ))}

                                    {/* Days of the month */}
                                    {daysInMonth.map((date) => (
                                        <div key={date} onClick={() => handleDateClick(date)}
                                            className={`text-theme_clr1 text-sm font-inter font-medium h-[35px] w-[35px] md:h-[40px] md:w-[40px] flex items-center justify-center rounded-full cursor-pointer p-1
                                        ${isSameDay(date, startDate) ? "bg-gradient-to-r from-white from-[-15%] to-theme_clr1" : ""}
                                        ${isSameDay(date, endDate) ? "bg-gradient-to-r from-white from-[-15%] to-theme_clr1" : ""}
                                        ${isWithinRange(date) ? "bg-blue-200" : ""}
                                        ${isSameDay(date, new Date()) ? "bg-yellow-300" : ""}
                                        hover:bg-gray-200`}
                                        >
                                            {format(date, "d")}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="border-black my-4" />

                <div className="flex flex-col lg:flex-row gap-4 xl:gap-5 pb-4">
                    <div className="left-info lg:w-[50%]">
                        <div className="title-name text-[22.69px] font-medium mb-2">Set your start time</div>
                        <div className="description font-medium text-theme_clr1 text-[15px] leading-tight">
                            What time on the 18th December 2024 would you like
                            your campaign to start?
                        </div>
                    </div>
                    <div className="right-info lg:w-[50%]">
                        <div className="bg-white border-2 border-theme_clr1 rounded-2xl p-4">
                            <div className="input-wrapper">
                                <input type="time" defaultValue={"19:15"} placeholder="HH:MM" className="w-full bg-theme_clr1 text-white rounded-full px-4 py-2" required style={{ themeColor: "light" }} />
                                {/* Custom CSS for time input icon color */}
                                <style jsx>
                                    {`
                                    /* Change the time picker icon color */
                                    input[type="time"]::-webkit-calendar-picker-indicator {
                                    filter: invert(100%);
                                    }
                                `}
                                </style>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="border-black my-4" />

                <div className="flex flex-col lg:flex-row gap-4 xl:gap-5">
                    <div className="left-info lg:w-[50%]">
                        <div className="title-name text-[22.69px] font-medium mb-2">Set your end time</div>
                        <div className="description font-medium text-theme_clr1 text-[15px] leading-tight">
                            What time on the 31st December 2024 would you like
                            your campaign to end?
                        </div>
                    </div>
                    <div className="right-info lg:w-[50%]">
                        <div className="bg-white border-2 border-theme_clr1 rounded-2xl p-4">
                            <div className="input-wrapper">
                                <input type="time" defaultValue={"23:45"} placeholder="HH:MM" className="w-full bg-theme_clr1 text-white rounded-full px-4 py-2" required style={{ themeColor: "light" }} />
                                {/* Custom CSS for time input icon color */}
                                <style jsx>
                                    {`
                                    /* Change the time picker icon color */
                                    input[type="time"]::-webkit-calendar-picker-indicator {
                                    filter: invert(100%);
                                    }
                                `}
                                </style>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
