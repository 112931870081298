import React, { useState, useEffect, useRef } from 'react';
import AuthBackground from '../assets/images/auth-banner.jpg';
import LinkAccountBG from '../assets/images/link-account-bg.png';
import { useSelector, useDispatch } from "react-redux";
import * as actions from '../redux/actions/auth';
import { useNavigate,useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const LinkAccounts = () => {
    const location = useLocation();
    const data = location.state;
    const auth = useSelector(state => state.auth);
    const { loading } = auth;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedArtist, setSelectedArtist] = useState(null);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    // Debounced search effect
    useEffect(() => {
        if (debounceTimeout) clearTimeout(debounceTimeout);

        if (searchTerm.trim()) {
            const timeout = setTimeout(async () => {
                try {
                    const results = await dispatch(actions.searchArtist(searchTerm, toast));
                    setSearchResults(results);
                    setShowDropdown(true); 
                } catch (error) {
                    toast.error('Failed to fetch search results.', {
                        position: "top-center",
                        theme: "colored",
                    });
                }
            }, 500); 

            setDebounceTimeout(timeout);
        } else {
            setSearchResults([]);
            setShowDropdown(false); 
        }

        return () => clearTimeout(debounceTimeout); 
    }, [searchTerm, dispatch]);

    // Handle click outside to close dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
                setSearchTerm(''); 
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleArtistSelect = (artist) => {
        setSelectedArtist(artist);
        setSearchTerm(artist.name); // Reset search term
        setShowDropdown(false); // Hide dropdown
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        if (!selectedArtist) {
            toast.warning('Please enter an artist name or Spotify URL', {
                position: "top-center",
                theme: "colored",
                hideProgressBar: true,
            });
        } else {
            let userData = {
                songstats_artist_id:selectedArtist.songstats_artist_id
            }

            await dispatch(actions.AddArtist(userData,navigate,toast));
            
        }
    };
    
    return (
        <div className='auth-page h-screen flex items-center justify-center'>
            <ToastContainer />
            <div className="grid grid-cols-1 lg:grid-cols-2 h-full w-full">
                <div className="grid-item">
                    <div className="left-sec bg-theme_clr1 h-full flex items-center justify-center text-center px-5 py-12 lg:p-[50px] 2xl:p-[69px]">
                        <form action="" className='font-manrope w-full max-w-[625px] mx-auto'>
                            <div type='button' className="logo-wrapper mb-6 md:mb-7 xl:mb-[33px] cursor-pointer" onClick={() => navigate('/')}>
                                <img src={require('../assets/images/logo.png')} alt="Logo" className="logo max-w-[145px] 2xl:max-w-[200px] mx-auto" />
                                <div className='line-bar bg-[#EDECF2] opacity-[15%] h-[1px] w-[64px] mx-auto'></div>
                            </div>
                            <div className='heading text-white text-[25px] lg:text-[32px] 2xl:text-[54px] font-medium mb-[21px] leading-tight'>It’s time to take control.</div>
                            <div className="text-white xl:text-xl font-manrope">Set up your account and take control of your career with UpNComer.</div>

                            <div className="input-cover text-left mt-10 2xl:mt-14 mb-4">
                                <div className="label text-white xl:text-xl font-manrope mb-5 xl:mb-8">Enter your Artist Name or Spotify URL</div>
                                <div className="relative" ref={dropdownRef}>
                                    <div className="flex items-center justify-between gap-[20px] xl:gap-[35px]">
                                        <span>
                                            <img src={require('../assets/images/icons/spotify-icon.png')} alt="Spotify" className='block min-w-[49px] w-[49px] h-[49px]' />
                                        </span>
                                        <input
                                            type="text"
                                            className='form-control'
                                            placeholder='Artist Name'
                                            autoComplete='off'
                                            required
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </div>
                                    {showDropdown && searchResults?.length > 0 && (
                                        <div className="search-results w-full bg-white rounded-lg shadow-lg overflow-auto mt-2 p-2" style={{ maxHeight: '250px' }}>
                                            <ul className="list-none">
                                                {searchResults.map((artist) => (
                                                    <li
                                                        key={artist.songstats_artist_id}
                                                        className={`cursor-pointer flex items-center gap-3 rounded-md px-3 py-2 ${selectedArtist === artist ? 'bg-gray-200' : ''}`}
                                                        onClick={() => handleArtistSelect(artist)}
                                                    >
                                                        <img
                                                            src={artist.avatar || 'default-avatar-url.png'} // Provide a fallback for avatar
                                                            alt={artist.name}
                                                            className="w-[35px] h-[35px] rounded-full object-cover"
                                                        />
                                                        <span className="text-gray-800 capitalize text-base font-bold">{artist.name}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="btn-wrapper mt-6 md:mt-10 2xl:mt-14">
                                <button
                                    type='submit'
                                    className='loader-button w-full bg-theme_clr2 text-white font-medium flex items-center justify-center gap-[4.5px] rounded-full mb-5 px-5 py-[11.5px]'
                                    disabled={loading}
                                    onClick={(e) => handleSubmit(e)}
                                >
                                    {loading ? (
                                        <span className="loader"> </span>
                                    ) : (
                                        'Let’s Go!'
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="grid-item">
                    <div className="right-sec h-full min-h-[90vh] flex items-center justify-center bg-cover bg-no-repeat bg-left px-5 py-12 lg:p-[50px] 2xl:p-[69px]" style={{ backgroundImage: `url(${LinkAccountBG})` }}></div>
                </div>
            </div>
        </div>
    );
};