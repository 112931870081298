import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import { Layout } from "./layouts/Layout";
import { SignUp } from "./pages/SignUp";
import { CompleteRegistration } from "./pages/CompleteRegistration";
import { Login } from "./pages/Login";
import { ForgotPassword } from "./pages/ForgotPassword";
import { EnterOtp } from "./pages/EnterOtp";
import { AdminLayout } from "./layouts/AdminLayout";
import { LinkAccounts } from "./pages/LinkAccounts";
import { Home } from "./pages/Home";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { TermsOfServices } from "./pages/TermsOfServices";

// Admin section pages
import { Dashboard } from "./pages/admin/Dashboard";
import { DataAnalyst } from "./pages/admin/DataAnalyst";
import { GrowthEngine } from "./pages/admin/GrowthEngine/GrowthEngine";
import Stepper from "./pages/admin/GrowthEngine/Stepper";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/verify-otp" element={<EnterOtp />} />
        <Route exact path="/complete-registration" element={<CompleteRegistration />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/link-accounts" element={<LinkAccounts />} />

        {/* Public site routes */}
        <Route path="/" element={<Layout><Home /></Layout>} />
        <Route path="/privacy-policy" element={<Layout><PrivacyPolicy /></Layout>} />
        <Route path="/terms-services" element={<Layout><TermsOfServices /></Layout>} />

        {/* Admin section routes */}
        <Route path="/dashboard" element={<AdminLayout><Dashboard /></AdminLayout>} />
        <Route path="/admin/data-analyst" element={<AdminLayout><DataAnalyst /></AdminLayout>} />
        <Route path="/admin/growth-engine" element={<AdminLayout><GrowthEngine /></AdminLayout>} />
        <Route path="/admin/growth-engine/campaign" element={<AdminLayout><Stepper /></AdminLayout>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
