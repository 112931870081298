import React, { useMemo, useEffect } from 'react';
import Chart from 'react-apexcharts';

const AreaChart = (props) => {

    const processedData = useMemo(() => {
        const data = props?.state?.data || [];
        return data.map((value) => (!isNaN(value) && value !== null ? value : 0)); // Replace invalid values with 0
    }, [props?.state?.data]);

    const processedCategories = useMemo(() => {
        const categories = props?.state?.categories || [];
        return categories.map((cat) =>
            typeof cat === 'string' || typeof cat === 'number' ? cat : ''
        ); // Ensure valid strings/numbers
    }, [props?.state?.categories]);

    // Prevent mismatched data and categories
    const isDataValid = useMemo(() => {
        return (
            processedData.length > 0 &&
            processedCategories.length > 0 &&
            processedData.length === processedCategories.length
        );
    }, [processedData, processedCategories]);

    const chartOptions = useMemo(() => ({
        chart: {
            id: 'area-chart',
            type: 'area',
            height: '180',
            toolbar: { show: false },
            zoom: { enabled: false },
        },
        dataLabels: { enabled: false },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 2,
            colors: ['#00067E'],
        },
        xaxis: {
            categories: processedCategories,
            labels: { show: false },
            axisBorder: { show: false },
            axisTicks: { show: false },
        },
        yaxis: { labels: { enabled: false, show: false } },
        fill: { opacity: 0.5 },
        grid: { show: false, padding: { top: 0, right: 0, bottom: 0, left: 0 } },
    }), [processedCategories]);

    const series = useMemo(() => [
        { name: props?.state?.name || '', data: processedData }
    ], [processedData, props?.state]);

    useEffect(() => {
    }, [processedData, processedCategories, isDataValid]);
    return (
        <>
            {isDataValid && (
                <Chart 
                key={`${processedData.join('-')}-${processedCategories.join('-')}`}
                options={chartOptions} series={series} type="area" height={180} />
            )}
        </>
    );
};

export default AreaChart;
